import { Close, ExitToApp, List, Person } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import DBSLogo from '../../assets/logo.png';
import { LOGIN_REDIRECT_URL } from '../../urls';

export default function Appbar() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <AppBar elevation={0} sx={{ background: '#000000' }}>
        <Toolbar disableGutters sx={{ paddingX: '32px' }}>
          <IconButton
            name="menu"
            size="large"
            edge="start"
            color="inherit"
            onClick={() => setOpen(true)}
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <img src={DBSLogo} alt="" width="100px" />
          <Typography style={{ flex: 1 }} />
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Drawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { width: '304px', bgcolor: '#222222' },
        }}
      >
        <Toolbar disableGutters sx={{ paddingX: '32px', bgcolor: '#222222', borderBottom: '1px' }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            onClick={() => setOpen(!open)}
            aria-label="close drawer"
            sx={{ mr: 2 }}
          >
            <Close htmlColor="#FFFFFF" />
          </IconButton>
          <img src={DBSLogo} alt="" width="100px" />
        </Toolbar>
        <Divider sx={{ bgcolor: '#555555' }} />
        <Stack sx={{ height: 'calc(100vh - 64px)' }}>
          <MenuItem
            onClick={() => {
              setOpen(!open);
              navigate('/');
            }}
            sx={{ color: '#FFFFFF' }}
          >
            <ListItemIcon>
              <List htmlColor="#FFFFFF" />
            </ListItemIcon>
            <ListItemText>Insurance Checklist Automation</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpen(!open);
              navigate('/admin');
            }}
            sx={{ color: '#FFFFFF', marginBottom: 'auto' }}
          >
            <ListItemIcon>
              <Person htmlColor="#FFFFFF" />
            </ListItemIcon>
            <ListItemText>Admin Screen</ListItemText>
          </MenuItem>
          <MenuItem sx={{ color: '#455057' }}>
            <ListItemText>Help</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              sessionStorage.clear();
              window.location.replace(LOGIN_REDIRECT_URL);
            }}
            sx={{ color: '#FFFFFF', marginBottom: '20px' }}
          >
            <ListItemIcon>
              <ExitToApp htmlColor="#FFFFFF" />
            </ListItemIcon>
            <ListItemText>Log Out</ListItemText>
          </MenuItem>
        </Stack>
      </Drawer>
    </>
  );
}
