import * as Yup from 'yup';

const draftValidation = Yup.object().shape({
  borrowerName: Yup.string().required("Borrower's Name is required"),
  caseType: Yup.string().required('Type of Cases is required'),
  docType: Yup.string().required('Type of document is required'),
  topDate: Yup.date().when('caseType', {
    is: 'new',
    then: (schema) => schema.required(),
  }),
  filesUploaded: Yup.array().min(1, 'At least one file is required').required(),
  filesStatus: Yup.array()
    .of(Yup.string().notOneOf(['error'], 'File upload failed. Please try again.'))
    .min(1, ''),
});

const nameValidationNew = Yup.object().shape({
  insuredName: Yup.string()
    .min(1)
    .required('Unable to retrieve Insured Name, please enter it manually.'),
});

const nameValidationRenewal = Yup.object().shape({
  insuredName: Yup.string().required('Insured Name is required'),
  gcinLabel: Yup.string().required('Collateral Owner Name is required'),
});

const remarkValidation = Yup.object().shape(
  {
    remarkType: Yup.string().when(['remarkText', 'filesUploaded', 'remarkLink'], {
      is: (...fields: string[]) => !fields.some((field) => field !== undefined),
      then: (schema) => schema.required('Required'),
    }),
    remarkText: Yup.string().when(['remarkType', 'filesUploaded', 'remarkLink'], {
      is: (...fields: string[]) => !fields.some((field) => field !== undefined),
      then: (schema) => schema.required('Required'),
    }),
    filesUploaded: Yup.string().when(['remarkText', 'remarkType', 'remarkLink'], {
      is: (...fields: string[]) => !fields.some((field) => field !== undefined),
      then: (schema) => schema.required('Required'),
    }),
    remarkLink: Yup.string().when(['remarkText', 'filesUploaded', 'remarkType'], {
      is: (...fields: string[]) => !fields.some((field) => field !== undefined),
      then: (schema) => schema.required('Required'),
    }),
  },
  [
    ['remarkType', 'remarkText'],
    ['remarkType', 'filesUploaded'],
    ['remarkType', 'remarkLink'],
    ['remarkText', 'filesUploaded'],
    ['remarkText', 'remarkLink'],
    ['filesUploaded', 'remarkLink'],
  ]
);

export { draftValidation, nameValidationNew, nameValidationRenewal, remarkValidation };
