import { Button, Container, SxProps, Theme, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import Checkmark from '../../assets/checkmark.png';
import { CreateTaskToolbar } from '../../components/toolbars';
import { GET_ENDPOINT } from '../../urls';
import headers from '../../utils/headers';
import { IQueryResponse } from '../task/interface';

const TaskIDBar = {
  bgcolor: '#FFF',
  minWidth: 'calc(100vw - 64px)',
  marginY: '8px',
  paddingY: '16px',
};
const containerStyle: SxProps<Theme> = {
  bgcolor: '#FFF',
  minWidth: 'calc(100vw - 64px)',
  marginY: '8px',
  paddingTop: '64px',
  paddingBottom: '72px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export default function Processing({ renewal }: { renewal?: boolean }) {
  const [urlParams] = useSearchParams();
  const taskId = urlParams.get('id') ?? '';
  const checklist = Boolean(urlParams.get('checklist') !== null);
  const { data: history, isLoading } = useQuery<IQueryResponse['history']>({
    queryKey: ['getTaskProcessing', taskId],
    queryFn: () =>
      axios
        .get<IQueryResponse>(`${GET_ENDPOINT}/${taskId}`, { headers })
        .then((res) => res.data?.history),
    refetchOnWindowFocus: false,
    initialData: [],
  });
  if (isLoading) {
    return null;
  }
  return (
    <>
      <CreateTaskToolbar type="processing" history={history?.[history.length - 1]} />
      <Container sx={TaskIDBar}>
        <Typography variant="h1" fontWeight={700}>
          Task ID {taskId}
        </Typography>
      </Container>
      <Container sx={containerStyle}>
        <img alt="checkmark" src={Checkmark} />
        <Typography variant="h1" fontWeight={600} marginY="21px">
          System is {!renewal && 'extracting Insured Name and generating checklist.'}
          {renewal &&
            Array.isArray(history) &&
            `${
              Boolean(history.filter((h) => h.action === 'SUBMITTED').length > 1) || checklist
                ? 'generating checklist.'
                : 'extracting Insured name.'
            }`}
        </Typography>
        <Typography fontSize="16px" lineHeight="32px" fontWeight={700}>
          You can either stay on this page waiting for the output to be generated or go back to Task
          List to create other new tasks.
        </Typography>
        <Typography fontSize="16px" lineHeight="32px" marginBottom="21px">
          Note that you&apos;ll need to pick up this case from “In Progress” of Task List to proceed
          the submission once the output is generated.
        </Typography>
        <Button
          variant="outlined"
          component={Link}
          to="/"
          sx={{
            p: '9px 16px',
          }}
        >
          <Typography fontWeight={600} lineHeight="20px">
            Back to Task List
          </Typography>
        </Button>
      </Container>
    </>
  );
}

Processing.defaultProps = {
  renewal: false,
};
