import { configureStore } from '@reduxjs/toolkit';
import taskStoreSlice from './taskStore';

const redux = configureStore({
  reducer: {
    taskStore: taskStoreSlice,
  },
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof redux.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof redux.dispatch;

export default redux;
