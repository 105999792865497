import {
  ChevronRight,
  FilterAlt,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  MoreVert,
  Search,
} from '@mui/icons-material';
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Popover,
  Select,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React from 'react';
import sortArrow from '../../assets/sortArrow.png';
import sortDropdown from '../../assets/sortDropdown.png';
import { useAppSelector } from '../../redux/hooks';
import headers from '../../utils/headers';
import {
  StatusChangeFunction,
  THandleCloseFunction,
  THandleSortClickFunction,
  TSubmitFunction,
  handleCloseFunction,
  handleSortClick,
  handleStatusClick,
  handleToggleSort,
  submitFunction,
} from './functions';

enum SortType {
  ASC = 'asc',
  DESC = 'desc',
}
type UploadedDate = Date | number[] | string | null;
interface SortQuery {
  sortBy: string;
  sortType: SortType;
}
interface FilterQuery {
  filterBy: string;
  filterType: string;
  filterValue: string;
}
interface Task {
  taskId: number;
  borrowerName: string;
  submittedBy: string;
  approvedBy: string;
  statusMsg: string;
  uploadedTime: UploadedDate;
  statusText: string;
  statusSubText: string;
}
interface QueryResponse {
  tasks: Task[];
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalTasks: number;
}
enum Status {
  In_Progress,
  To_Review,
  Returned,
  Completed,
}

const tableButtonStyle: SxProps = {
  padding: '8px 16px',
  maxHeight: '32px',
  borderRadius: '4px',
  ':active': { backgroundColor: '#59656D' },
};
const paginationStyle: SxProps = {
  position: 'absolute',
  inset: 'auto 0 16px 0',
  width: '100%',
  color: '#6E7880',
  borderBottom: 0,
  overflow: 'hidden',
  '& .MuiTablePagination-toolbar': {
    p: 0,
    minHeight: '32px',
    maxHeight: '32px',
  },
  '& .MuiTablePagination-displayedRows': {
    order: '-2',
  },
  '& .MuiTablePagination-selectLabel, .MuiInputBase-root': {
    order: '-1',
    marginX: '8px',
  },
};
const iconStyle = {
  scale: '1.2',
  marginRight: '8px',
};

interface IColumnOptions {
  index: number;
  states: {
    sort: (SortType | null)[];
    anchorSort: (HTMLElement | null)[];
    anchorFilterEl: HTMLButtonElement | null;
    filter: FilterQuery[];
  };
  functions: {
    handleSortClick: THandleSortClickFunction;
    handleCloseFunction: THandleCloseFunction;
    handleFilterClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    submitFunction: TSubmitFunction;
  };
  setters: {
    setFilter: React.Dispatch<React.SetStateAction<FilterQuery[]>>;
    setFilterQuery: React.Dispatch<React.SetStateAction<FilterQuery[]>>;
    setAnchorSort: React.Dispatch<React.SetStateAction<(HTMLElement | null)[]>>;
    setFilterAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
    setSort: React.Dispatch<React.SetStateAction<(SortType | null)[]>>;
    setSortQuery: React.Dispatch<React.SetStateAction<SortQuery[]>>;
  };
}
function ColumnOptions({
  index,
  states: { sort, anchorSort, anchorFilterEl, filter },
  functions: {
    handleSortClick: sortClick,
    handleCloseFunction: handleClose,
    handleFilterClick,
    submitFunction: handleSubmit,
  },
  setters: { setAnchorSort, setFilter, setFilterQuery, setFilterAnchorEl, setSort, setSortQuery },
}: IColumnOptions) {
  function onClose(sortType?: SortType) {
    if (sortType) {
      handleClose(
        index,
        anchorSort,
        setAnchorSort,
        setFilterAnchorEl,
        setSort,
        setSortQuery,
        sortType
      );
    } else {
      handleClose(index, anchorSort, setAnchorSort, setFilterAnchorEl, setSort, setSortQuery);
    }
  }
  return (
    <>
      {sort[index] === SortType.ASC && (
        <img
          src={sortArrow}
          data-testid={`arrowAsc-${index}`}
          alt="arrowAsc"
          style={{ scale: '1.2' }}
        />
      )}
      {sort[index] === SortType.DESC && (
        <img
          src={sortArrow}
          data-testid={`arrowDesc-${index}`}
          alt="arrowDesc"
          style={{ transform: 'rotate(180deg)', scale: '1.2' }}
        />
      )}
      {filter[index] && <FilterAlt fontSize="small" />}
      <IconButton
        onClick={(event) => sortClick(event, index, setAnchorSort, anchorSort)}
        sx={{ padding: 0 }}
        data-testid={`more-${index}`}
      >
        <MoreVert fontSize="small" />
      </IconButton>
      <Menu
        disableEnforceFocus
        id={`sort-menu-${index}`}
        anchorEl={anchorSort[index]}
        open={Boolean(anchorSort[index])}
        elevation={1}
        onClose={() => onClose()}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem id={`sort-asc-${index}`} onClick={() => onClose(SortType.ASC)}>
          <img src={sortDropdown} alt="arrowAsc" style={iconStyle} />
          Sort Ascending
        </MenuItem>
        <MenuItem id={`sort-asc-${index}`} onClick={() => onClose(SortType.DESC)}>
          <img
            src={sortDropdown}
            alt="arrowDesc"
            style={{ ...iconStyle, transform: 'rotate(180deg)' }}
          />
          Sort Descending
        </MenuItem>
        <Divider />
        <MenuItem disableRipple sx={{ p: 0, m: 0 }}>
          <Button
            color="inherit"
            onClick={handleFilterClick}
            fullWidth
            sx={{ p: '6px 17px', m: 0 }}
          >
            <FilterAlt
              fontSize="small"
              sx={{ maxWidth: '16px', marginRight: '8px', maskType: 'luminance' }}
              htmlColor="#777777"
            />
            <Typography flexGrow={1} textAlign="left">
              Filter
            </Typography>
            <ChevronRight
              fontSize="small"
              htmlColor="#777777"
              sx={{ maxWidth: '16px', marginRight: '8px', maskType: 'luminance' }}
            />
          </Button>
          <Popover
            id="simple-popover"
            anchorEl={anchorFilterEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorFilterEl)}
            elevation={1}
            onClose={() => onClose()}
            sx={{
              padding: '16px',
            }}
          >
            <Typography margin="16px 0 0 16px" fontWeight={700} lineHeight="20px">
              Show results that:
            </Typography>
            <Formik
              key={`Formik-${index}-filter-form`}
              initialValues={{
                filterType: filter[index]?.filterType ?? 'Contains',
                filterValue: filter[index]?.filterValue ?? '',
              }}
              onSubmit={({ filterType, filterValue }) =>
                handleSubmit(filter, filterType, filterValue, index, setFilter, setFilterQuery)
              }
            >
              {({ values, handleChange }) => (
                <Form autoComplete="off">
                  <Stack spacing="8px" margin="16px">
                    <Select
                      name="filterType"
                      sx={{ display: 'block', width: '264px' }}
                      value={values.filterType}
                      onChange={handleChange}
                    >
                      <MenuItem value="Contains">Contains</MenuItem>
                      <MenuItem value="Is equal to">Is equal to</MenuItem>
                      <MenuItem value="Is not equal to">Is not equal to</MenuItem>
                      <MenuItem value="Starts with">Starts with</MenuItem>
                      <MenuItem value="Does not contain">Does not contain</MenuItem>
                      <MenuItem value="Ends with">Ends with</MenuItem>
                      <MenuItem value="Is null">Is null</MenuItem>
                      <MenuItem value="Is not null">Is not null</MenuItem>
                      <MenuItem value="Is empty">Is empty</MenuItem>
                      <MenuItem value="Is not empty">Is not empty</MenuItem>
                    </Select>
                    <TextField
                      fullWidth
                      onKeyDown={(e) => {
                        e.stopPropagation();
                      }}
                      placeholder="Value"
                      value={values.filterValue}
                      name="filterValue"
                      onChange={handleChange}
                    />
                  </Stack>
                  <Stack direction="row" spacing="8px" margin="16px">
                    <Button
                      variant="contained"
                      color="inherit"
                      sx={{
                        bgcolor: '#EEEEEE',
                        color: '#000000',
                        p: '9px 16px',
                        minWidth: '128px',
                      }}
                      onClick={() => {
                        onClose();
                        const newFilter = filter;
                        newFilter[index] = null as any;
                        setFilter(newFilter);
                        setFilterQuery(newFilter.filter((a) => a !== null));
                      }}
                    >
                      <Typography fontWeight={700} lineHeight="20px">
                        Reset
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        p: '9px 16px',
                        minWidth: '128px',
                      }}
                      onClick={() => onClose()}
                    >
                      <Typography fontWeight={700} lineHeight="20px">
                        Apply Filters
                      </Typography>
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Popover>
        </MenuItem>
      </Menu>
    </>
  );
}

function TablePaginationActions({
  page,
  onPageChange,
  count,
  rowsPerPage,
}: TablePaginationActionsProps) {
  const buttonStyle: SxProps = {
    width: '32px',
    height: '32px',
    minWidth: '0',
    borderRadius: '4px',
  };
  const totalPages = Math.ceil(count / rowsPerPage);
  return (
    <Stack direction="row" alignItems="center" spacing="8px">
      <Button
        onClick={() => onPageChange(null, 0)}
        disabled={page === 0}
        aria-label="first page"
        variant="outlined"
        sx={buttonStyle}
      >
        <FirstPage fontSize="small" />
      </Button>
      <Button
        onClick={() => onPageChange(null, page - 1)}
        disabled={page === 0}
        aria-label="previous page"
        variant="outlined"
        sx={buttonStyle}
      >
        <KeyboardArrowLeft fontSize="small" />
      </Button>
      <TextField
        aria-label="current page"
        variant="outlined"
        role="textbox"
        type="number"
        value={page + 1}
        onChange={(e) => onPageChange(null, parseInt(e.currentTarget.value, 10) - 1)}
        InputProps={{
          inputProps: {
            min: 1,
            max: totalPages,
            style: {
              padding: '8px',
            },
          },
          sx: { height: '32px', width: '64px', borderRadius: '4px' },
        }}
      />
      <Button
        onClick={() => onPageChange(null, page + 1)}
        disabled={page >= totalPages - 1}
        aria-label="next page"
        variant="outlined"
        sx={buttonStyle}
      >
        <KeyboardArrowRight fontSize="small" />
      </Button>
      <Button
        sx={buttonStyle}
        onClick={() => onPageChange(null, totalPages - 1)}
        disabled={page >= totalPages - 1}
        variant="outlined"
        aria-label="last page"
      >
        <LastPage fontSize="small" />
      </Button>
      <Typography color="#6E7880" lineHeight="24px" letterSpacing="-0.25px">
        Page{' '}
      </Typography>
      <Typography color="#172733" fontWeight="600" letterSpacing="-0.25px">
        {page + 1}&nbsp;of&nbsp;{totalPages === 0 ? 1 : totalPages}
      </Typography>
    </Stack>
  );
}

interface IStatusTabs {
  status: number;
  search: string;
  handleStatusChange: StatusChangeFunction;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setStatus: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  admin: boolean;
}
function StatusTabs({
  status,
  search,
  handleStatusChange,
  setSearch,
  setStatus,
  setPage,
  admin,
}: IStatusTabs) {
  return (
    <Stack direction="row" gap="8px" padding="16px" marginY="16px" alignItems="center">
      <Button
        onClick={() => handleStatusChange(Status.In_Progress, setStatus, setPage)}
        variant={status === Status.In_Progress ? 'contained' : 'outlined'}
        size="small"
        color="secondary"
        sx={tableButtonStyle}
      >
        <Typography variant="table_button">In Progress</Typography>
      </Button>
      <Button
        disabled={!admin}
        onClick={() => handleStatusChange(Status.To_Review, setStatus, setPage)}
        variant={status === Status.To_Review ? 'contained' : 'outlined'}
        size="small"
        color="secondary"
        sx={tableButtonStyle}
      >
        <Typography variant="table_button">To Review</Typography>
      </Button>
      <Button
        disabled={!admin}
        onClick={() => handleStatusChange(Status.Returned, setStatus, setPage)}
        variant={status === Status.Returned ? 'contained' : 'outlined'}
        size="small"
        color="secondary"
        sx={tableButtonStyle}
      >
        <Typography variant="table_button">Returned</Typography>
      </Button>
      <Button
        onClick={() => handleStatusChange(Status.Completed, setStatus, setPage)}
        variant={status === Status.Completed ? 'contained' : 'outlined'}
        size="small"
        color="secondary"
        sx={tableButtonStyle}
      >
        <Typography variant="table_button">Completed</Typography>
      </Button>
      <TextField
        placeholder="Search"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        InputProps={{
          sx: {
            height: '40px',
            width: '354px',
            padding: '8px 16px',
          },
          inputProps: {
            style: {
              padding: '8px 0px',
            },
          },
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        sx={{
          marginLeft: 'auto',
        }}
      />
    </Stack>
  );
}

const statusPayload = {
  home: [
    ['PROCESSING', 'IN_PROGRESS', 'NAME_EXTRACTED', 'DRAFT', 'NAME_EXTRACTED_DRAFT'],
    ['UNDER_REVIEW', 'REVIEW_DRAFT'],
    ['RETURNED'],
    ['COMPLETED', 'FAILED'],
  ],
  admin: [['DRAFT'], ['UNDER_REVIEW', 'REVIEW_DRAFT'], ['RETURNED'], ['APPROVED', 'REJECTED']],
};
interface Page {
  page: number;
  rowsPerPage: number;
  fetchUrl: string;
}
const fetchTaskList = (
  { page, rowsPerPage, fetchUrl }: Page,
  sort: SortQuery[],
  status: number,
  search: string,
  filter: FilterQuery[],
  caseType: string | null,
  admin?: boolean
) =>
  axios
    .post(
      fetchUrl,
      {
        pageSize: rowsPerPage,
        pageNumber: page + 1,
        filter,
        sort,
        search,
        status: statusPayload[admin ? 'admin' : 'home'][status],
        caseType,
      },
      { headers }
    )
    .then((res) => res.data);
const defaultSortQuery = [{ sortBy: 'lastUpdateTime', sortType: SortType.DESC }];
const defaultNullArray = new Array(4).filter((a) => a !== null);
export default function DataTable({
  fetchUrl,
  admin,
  columns,
}: {
  fetchUrl: string;
  admin?: boolean;
  columns: ColumnDef<any, any>[];
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorSort, setAnchorSort] = React.useState<Array<HTMLElement | null>>(defaultNullArray);
  const [sort, setSort] = React.useState<Array<SortType | null>>([]);
  const [sortQuery, setSortQuery] = React.useState<SortQuery[]>(defaultSortQuery);
  const caseType = useAppSelector((state) => state.taskStore.caseType);
  const [filter, setFilter] = React.useState<FilterQuery[]>(new Array(4));
  const [filterQuery, setFilterQuery] = React.useState<FilterQuery[]>(defaultNullArray);
  const [status, setStatus] = React.useState<number>(Status.In_Progress);
  const [anchorFilterEl, setFilterAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [search, setSearch] = React.useState<string>('');
  const { data } = useQuery<QueryResponse>(
    ['getTaskList', page, rowsPerPage, sortQuery, status, search, filterQuery, caseType, fetchUrl],
    () =>
      fetchTaskList(
        { page, rowsPerPage, fetchUrl },
        sortQuery.length === 0 ? defaultSortQuery : sortQuery,
        status,
        search,
        filterQuery,
        !admin ? caseType.split(' ')[0].toUpperCase() : null,
        admin
      ),
    {
      keepPreviousData: true,
      initialData: {
        tasks: [],
        pageNumber: 1,
        pageSize: 1,
        totalPages: 1,
        totalTasks: 0,
      },
    }
  );

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const states = { sort, anchorSort, anchorFilterEl, filter };
  const functions = {
    handleSortClick,
    handleCloseFunction,
    handleFilterClick,
    submitFunction,
  };
  const setters = {
    setFilter,
    setFilterQuery,
    setAnchorSort,
    setFilterAnchorEl,
    setSort,
    setSortQuery,
  };
  const table = useReactTable({
    data: data?.tasks ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      <StatusTabs
        status={status}
        search={search}
        handleStatusChange={handleStatusClick}
        setSearch={setSearch}
        setStatus={setStatus}
        setPage={setPage}
        admin={admin ?? false}
      />
      <TableContainer sx={{ minHeight: 'calc(94vh - 128px - 153px)', position: 'relative' }}>
        <Table sx={{ marginBottom: '85px' }}>
          <TableHead sx={{ bgcolor: '#F4F4F4' }}>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <TableCell key={header.id} sx={{ p: 1 }}>
                    <Stack direction="row" alignItems="center">
                      <Typography
                        component={Button}
                        name={`sort-${index}`}
                        role="button"
                        onClick={() => handleToggleSort(index, sort[index], setSort, setSortQuery)}
                        variant="table_header"
                        marginRight="1px"
                        color="#222222"
                        sx={{ justifyContent: 'flex-start', textTransform: 'none', padding: 0 }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Typography>
                      <ColumnOptions
                        index={index}
                        states={states}
                        functions={functions}
                        setters={setters}
                      />
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} sx={{ verticalAlign: 'top', p: 1, height: '58px' }}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40]}
                colSpan={4}
                count={data?.totalTasks || 0}
                rowsPerPage={rowsPerPage}
                page={data?.pageNumber ? data.pageNumber - 1 : page}
                labelDisplayedRows={({ from, to, count }) =>
                  `Showing ${from}-${to} of ${count} items,`
                }
                labelRowsPerPage="items per page"
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  variant: 'outlined',
                  sx: {
                    height: '32px',
                    borderRadius: '2px',
                  },
                }}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
                ActionsComponent={TablePaginationActions}
                sx={paginationStyle}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <Divider sx={{ position: 'absolute', left: 0, right: 0, bottom: '80px' }} />
      </TableContainer>
    </>
  );
}

DataTable.defaultProps = {
  admin: false,
};
