import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useFormikContext } from 'formik';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import {
  ADMIN_APPROVE_TASK,
  ADMIN_REJECT_TASK,
  ADMIN_RETURN_TASK,
  ADMIN_SUBMIT_TASK,
} from '../../urls';
import headers from '../../utils/headers';

function dataFn(taskType: string, data: any) {
  if (taskType === 'ADD') {
    return data.value;
  }
  if (taskType === 'REFRESH') {
    return [
      {
        checklistItem: data.checklistItem,
        lookupName: data.lookupName,
        documentName: data.documentName,
        filename: data.filesUploaded,
      },
    ];
  }
  if (taskType === 'EDIT') {
    return data.data;
  }
  return data.removedItems.map((d: any) => JSON.parse(d));
}

const ApproveTask = (taskId: number, taskType: string, data: any) =>
  axios.post(
    ADMIN_APPROVE_TASK,
    {
      taskId,
      taskType,
      data: dataFn(taskType, data),
    },
    { headers }
  );
const RejectTask = (taskId: number, taskType: string, data: any) =>
  axios.post(
    ADMIN_REJECT_TASK,
    {
      taskId,
      taskType,
      data: dataFn(taskType, data),
    },
    { headers }
  );
const ReturnTask = (taskId: number, taskType: string, data: any) =>
  axios.post(
    ADMIN_RETURN_TASK,
    {
      taskId,
      taskType,
      data: dataFn(taskType, data),
    },
    { headers }
  );
const SubmitTask = (taskId: number, taskType: string, data: any) =>
  axios.post(
    ADMIN_SUBMIT_TASK,
    {
      taskId,
      taskType,
      data: dataFn(taskType, data),
    },
    { headers }
  );

const onSuccess = (taskType: string, values: any, navigate: NavigateFunction) => {
  navigate('/admin');
};
export default function CheckerDialog({ taskType }: { taskType: string }) {
  const [urlParams] = useSearchParams();
  const taskId = urlParams.get('id') as unknown as number;
  const navigate = useNavigate();
  const { values, setFieldValue } = useFormikContext<any>();
  const { mutate: approve } = useMutation(() => ApproveTask(taskId, taskType, values), {
    onSuccess: () => onSuccess(taskType, values, navigate),
  });
  const { mutate: reject } = useMutation(() => RejectTask(taskId, taskType, values), {
    onSuccess: () => onSuccess(taskType, values, navigate),
  });
  const { mutate: returnTask } = useMutation(() => ReturnTask(taskId, taskType, values), {
    onSuccess: () => onSuccess(taskType, values, navigate),
  });
  const { mutate: submitTask } = useMutation(() => SubmitTask(taskId, taskType, values), {
    onSuccess: () => onSuccess(taskType, values, navigate),
  });
  const handleClose = () => {
    setFieldValue('openApprove', false);
    setFieldValue('openReject', false);
    setFieldValue('openReturn', false);
    setFieldValue('openDialog', false);
  };
  const { openApprove, openReject, openReturn, openDialog } = values;
  return (
    <Dialog
      open={openApprove || openReject || openReturn || openDialog}
      onClose={() => handleClose()}
      fullWidth
      slotProps={{ backdrop: { sx: { backgroundColor: 'rgba(0, 0, 0, 0.7)' } } }}
      PaperProps={{ sx: { maxWidth: '680px', padding: '24px' } }}
    >
      <DialogTitle sx={{ p: 0 }} fontWeight={400} fontSize="24px">
        {openApprove && 'Approve this Case?'}
        {openReject && 'Reject this Case?'}
        {openReturn && 'Return this Case?'}
        {openDialog && 'Submit this Case?'}
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <DialogContentText color="#222222" fontWeight={700} margin="16px 0px 8px 0px">
          {openApprove && 'Are you sure you want to approve this case?'}
          {openReject && 'Are you sure you want to reject this case?'}
          {openReturn && 'Are you sure you want to return this case to the maker?'}
          {openDialog && 'You are going to submit this case to checkers.'}
        </DialogContentText>
        {openDialog && (
          <DialogContentText color="#222222" marginBottom="64px">
            Please make sure you’ve entered the correct keywords.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        {openApprove && (
          <Button
            onClick={() => approve()}
            variant="contained"
            sx={{ p: '9px 16px', width: '149px' }}
          >
            <Typography fontWeight={700}>Approve</Typography>
          </Button>
        )}
        {openReject && (
          <Button
            onClick={() => reject()}
            variant="contained"
            sx={{ p: '9px 16px', width: '149px' }}
          >
            <Typography fontWeight={700}>Reject</Typography>
          </Button>
        )}
        {openReturn && (
          <Button
            onClick={() => returnTask()}
            variant="contained"
            sx={{ p: '9px 16px', width: '149px' }}
          >
            <Typography fontWeight={700}>Return</Typography>
          </Button>
        )}
        {openDialog && (
          <Button
            onClick={() => submitTask()}
            variant="contained"
            sx={{ p: '9px 16px', width: '149px' }}
          >
            <Typography fontWeight={700}>Submit</Typography>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
