import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid, Stack, Toolbar, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { Link, useLocation } from 'react-router-dom';
import { activeButtonStyle } from './utils';
import ToolbarHistory from './ToolbarHistory';

interface FormikValues {
  checklistItem: '';
  lookup: '';
  keyword: '';
  openDialog: false;
}
const buttonStyle = {
  bgcolor: '#EEEEEE',
  color: '#000000',
  ':disabled': { bgcolor: '#cccccc', color: '#FFF', boxShadow: 2 },
  p: '9px 16px',
  minWidth: '128px',
};
interface IHistory {
  action: string;
  name: string;
  date: string;
}
export default function CheckerToolbar({ history }: { history: IHistory }) {
  const location = useLocation();
  const { setFieldValue } = useFormikContext<FormikValues>();
  return (
    <Box sx={{ backgroundColor: '#FFF', mb: '16px' }}>
      <Toolbar disableGutters sx={{ paddingX: '32px' }}>
        <Button component={Link} startIcon={<ArrowBackIcon />} sx={{ color: '#000' }} to="/admin">
          <Typography fontWeight={600} fontSize="16px" lineHeight="16px" noWrap width="75px">
            Task List
          </Typography>
        </Button>

        <Grid container columnSpacing="16px">
          <Grid item sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <Stack direction="row">{history && <ToolbarHistory history={history} />}</Stack>
          </Grid>
          {location.pathname !== '/admin/completed' && (
            <>
              <Grid item>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => setFieldValue('openReject', true)}
                  sx={buttonStyle}
                >
                  <Typography fontWeight={700}>Reject</Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="inherit"
                  sx={buttonStyle}
                  onClick={() => setFieldValue('openReturn', true)}
                >
                  <Typography fontWeight={700}>Return</Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setFieldValue('openApprove', true)}
                  sx={activeButtonStyle(true)}
                >
                  <Typography fontWeight={700}>Approve</Typography>
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Toolbar>
    </Box>
  );
}
