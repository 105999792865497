import { Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useQuery } from '@tanstack/react-query';
import CheckerDialog from '../Dialog';
import { getChecklistItems } from '../StaticListTable';

interface AddFormValue {
  value: {
    checklistItem: string;
    lookupName: string;
    keyword: string;
    documentName: string;
  }[];
  openDialog: boolean;
  openReject: boolean;
  openReturn: boolean;
  openApprove: boolean;
}
interface IChecklistItems {
  checkListItem: string;
  locked: boolean;
  metadata: {
    [index: string]: string[];
  };
  description: string;
}
export default function Add() {
  const { values } = useFormikContext<AddFormValue>();
  const { data } = useQuery<IChecklistItems[]>(['ChecklistItems'], () => getChecklistItems(), {
    initialData: [],
  });
  return (
    <Container sx={{ bgcolor: '#FFF', minWidth: 'calc(100vw - 64px)', paddingBottom: '32px' }}>
      <Stack direction="row" justifyContent="space-between" paddingY="16px">
        <Typography variant="h1" marginRight="8px">
          Add Keywords
        </Typography>
      </Stack>
      {values.value?.map((v, index) => (
        <Grid
          container
          spacing={2}
          key={`${JSON.stringify(v)}`}
          columns={2}
          sx={{ marginBottom: '32px' }}
        >
          <Grid item xs={2}>
            <Divider sx={{ borderColor: index !== 0 ? '#BBBBBB' : '' }} />
          </Grid>
          <Grid item width="281px">
            <Typography variant="form_label">Checklist Item</Typography>
          </Grid>
          <Grid item xs>
            <Typography>
              {data.filter((item) => item.checkListItem === v.checklistItem)?.[0]?.description}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Divider />
          </Grid>
          <Grid item width="281px">
            <Typography variant="form_label">Document name</Typography>
          </Grid>
          <Grid item xs>
            <Typography>{v.documentName}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Divider />
          </Grid>
          <Grid item width="281px">
            <Typography variant="form_label">Lookup name</Typography>
          </Grid>
          <Grid item xs>
            <Typography>{v.lookupName}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Divider />
          </Grid>
          <Grid item width="281px">
            <Typography variant="form_label">Keyword</Typography>
          </Grid>
          <Grid item xs>
            <Typography>{v.keyword}</Typography>
          </Grid>
        </Grid>
      ))}
      <CheckerDialog taskType="ADD" />
    </Container>
  );
}
