import { Container, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import CheckerToolbar from '../../components/toolbars/ChekerToolbar';
import MakerToolbar from '../../components/toolbars/MakerToolbar';
import EditReview from '../../pages/admin/checker/Edit';
import EditPage from '../../pages/admin/maker/Edit';
import { useAppSelector } from '../../redux/hooks';
import ViewToolbar from './ViewToolbar';
import { QueryResponse } from './interfaces';

const validatationSchema = Yup.object().shape({
  data: Yup.array().min(1, 'Please select at least one item'),
});
const TaskIDBar = {
  bgcolor: '#FFF',
  minWidth: 'calc(100vw - 64px)',
  marginY: '8px',
  paddingY: '16px',
};
interface IHistory {
  action: string;
  name: string;
  date: string;
}
export default function Edit({
  data,
  checker,
  history,
  maker,
}: {
  data: QueryResponse['data'];
  checker?: boolean;
  history: IHistory[];
  maker: string;
}) {
  const [urlParams] = useSearchParams();
  const taskID = urlParams.get('id');
  const view = useAppSelector(
    (state) => state.taskStore.authLevel === 1 || (state.taskStore.username === maker && checker)
  );
  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={validatationSchema}
      initialValues={{
        data: data ?? [],
        openDialog: false,
        openReject: false,
        openReturn: false,
        openApprove: false,
      }}
      onSubmit={() => {}}
    >
      <>
        {view && <ViewToolbar />}

        {!view &&
          (checker ? (
            <CheckerToolbar history={history[history.length - 1]} />
          ) : (
            <MakerToolbar taskType="EDIT" history={history[history.length - 1]} />
          ))}
        <Container sx={TaskIDBar}>
          <Typography variant="h1" fontWeight={700}>
            Task ID {taskID}
          </Typography>
        </Container>
        {view && <EditReview data={data} />}
        {!view && (checker ? <EditReview data={data} /> : <EditPage />)}
      </>
    </Formik>
  );
}
Edit.defaultProps = {
  checker: false,
};
