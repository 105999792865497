import { Delete } from '@mui/icons-material';
import { Box, Button, Chip, CircularProgress, Link, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { saveAs } from 'file-saver';
import React from 'react';
import urlIcon from '../../assets/URL.png';
import pdfIcon from '../../assets/pdf-icon.png';
import csvIcon from '../../assets/csv-icon.png';
import headers from '../../utils/headers';

interface IAttachmentBox {
  width: string;
  children: React.ReactNode;
  status: string;
}

const boxStyle = (status: string) => ({
  border: status === 'error' ? '1px solid #CC0000' : '1px solid #CCCCCC',
  ':hover': {
    borderColor: status === 'error' ? 'FF2300' : '#000',
  },
  padding: '0px 8px',
  mb: '9px',
});

function AttachmentBox({ width, children, status }: IAttachmentBox) {
  return (
    <Box maxWidth={width} sx={boxStyle(status)}>
      {children}
    </Box>
  );
}

const AttachmentStyle = (width: string) => ({
  width: `calc(${width} - 16px)`,
  justifyContent: 'start',
  bgcolor: 'transparent',
  margin: '3px',
  '& > .MuiChip-label': {
    width: '100%',
  },
  '& > .MuiChip-deleteIcon': {
    marginLeft: 'auto',
  },
});
interface IAttachmentFile {
  filename: string;
  status: string;
  width: string;
  getEndpoint: string;
  handleDelete?: () => void;
}
interface IFileLabel {
  filename: string;
  status: string;
  getEndpoint: string;
}

function FileLabel({ filename, status, getEndpoint }: IFileLabel) {
  const { mutate } = useMutation(() =>
    axios
      .get(getEndpoint, {
        headers,
        responseType: 'blob',
      })
      .then((res) => saveAs(res.data, filename))
  );
  if (status === 'pending') {
    return <Typography>{filename}</Typography>;
  }
  return (
    <Button data-testid="file" component={Link} onClick={() => mutate()}>
      {filename}
    </Button>
  );
}

function FileAttachment({ width, filename, status, handleDelete, getEndpoint }: IAttachmentFile) {
  return (
    <AttachmentBox width={`calc(${width} - 16px)`} status={status}>
      <Chip
        label={<FileLabel filename={filename} status={status} getEndpoint={getEndpoint} />}
        sx={AttachmentStyle(width)}
        deleteIcon={
          status === 'pending' ? (
            <CircularProgress size={20} />
          ) : (
            <Delete
              data-testid="DeleteIcon"
              style={{ color: status === 'error' ? '#CC0000' : '#777777' }}
            />
          )
        }
        onDelete={handleDelete}
        icon={
          <img
            alt={`${filename.endsWith('.csv') ? 'csv' : 'pdf'}-icon`}
            src={filename.endsWith('.csv') ? csvIcon : pdfIcon}
          />
        }
      />
    </AttachmentBox>
  );
}
interface IAttachmentLink {
  width: string;
  link: string;
  handleDelete?: () => void;
}
function LinkAttachment({ width, link, handleDelete }: IAttachmentLink) {
  return (
    <AttachmentBox width={`calc(${width} - 16px)`} status="success">
      <Chip
        label={
          <Link href={link} target="_blank">
            {link}
          </Link>
        }
        sx={AttachmentStyle(width)}
        deleteIcon={<Delete data-testid="DeleteIcon" style={{ color: '#777777' }} />}
        onDelete={handleDelete}
        icon={<img alt="link-icon" src={urlIcon} />}
      />
    </AttachmentBox>
  );
}
export { FileAttachment, LinkAttachment };

FileAttachment.defaultProps = {
  handleDelete: undefined,
};

LinkAttachment.defaultProps = {
  handleDelete: undefined,
};
