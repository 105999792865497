import { Box, Toolbar } from '@mui/material';

export default function ViewToolbar({ children }: { children: React.ReactNode }) {
  return (
    <Box sx={{ backgroundColor: '#FFF', mb: '16px' }}>
      <Toolbar disableGutters sx={{ paddingX: '32px' }}>
        {children}
      </Toolbar>
    </Box>
  );
}
