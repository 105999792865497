import { Outlet } from 'react-router';
import { Typography } from '@mui/material';
import DefaultToolbar from '../components/toolbars';
import { useAppSelector } from '../redux/hooks';
import ViewToolbar from '../components/toolbars/ViewToolbar';

/*
This is the default layout for Maker view. 
*/
export function DefaultLayout() {
  const view = useAppSelector((state) => state.taskStore.authLevel === 1);
  return (
    <>
      {!view ? (
        <DefaultToolbar />
      ) : (
        <ViewToolbar>
          {' '}
          <Typography variant="toolbar_h1" sx={{ flexGrow: 1 }}>
            Insurance Checklist Automation
          </Typography>
        </ViewToolbar>
      )}
      <Outlet />
    </>
  );
}
/*
This is the create/edit layout for Maker view. 
*/
export function TaskRouterLayout() {
  return <Outlet />;
}
export function AdminLayout() {
  return <Outlet />;
}
