import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useFormikContext } from 'formik';

const DatePickerStyle = {
  style: {
    padding: '10px 16px',
  },
  sx: {
    height: '40px',
    width: '348px',
    padding: '10px 16px',
  },
};

export default function DatePickerInput({ name }: { name: string }) {
  const { values, setFieldValue } = useFormikContext<any>();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disableMaskedInput
        value={values[name as keyof typeof values]}
        onChange={(newValue) => {
          setFieldValue('topDate', newValue.toISOString());
        }}
        InputProps={DatePickerStyle}
        inputFormat="DD MMM YYYY"
        renderInput={(params) => (
          <TextField
            {...params}
            id={name}
            name={name}
            role="textbox"
            InputLabelProps={{
              sx: { color: '#999999' },
            }}
            sx={{
              '& .MuiInputBase-root .MuiInputBase-input': {
                padding: '0px',
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
