import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Divider,
  Toolbar,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { ADMIN_GET_TASK } from '../../urls';
import Add from './add';
import Edit from './edit';
import { QueryResponse } from './interfaces';
import Refresh from './refresh';
import Remove from './remove';
import headers from '../../utils/headers';
import WorkflowHistory from '../../components/workflowHistory';

const containerStyle = {
  bgcolor: '#FFF',
  minWidth: 'calc(100vw - 64px)',
  marginY: '8px',
  paddingTop: '4px',
  paddingBottom: '12px',
};
const initialData: QueryResponse = {
  taskId: 1,
  taskType: '',
  data: [
    {
      keywordId: null,
      checklistItem: '',
      documentName: '',
      lookupName: '',
      keyword: '',
      filename: '',
      previousKeyword: '',
    },
  ],
  history: [],
  maker: '',
  checker: '',
};
const TaskIDBar = {
  bgcolor: '#FFF',
  minWidth: 'calc(100vw - 64px)',
  marginY: '8px',
  paddingY: '16px',
};
export function mapHistory(history: QueryResponse['history']) {
  return history.map(({ user, action, timestamp }) => ({
    name: user,
    action,
    date: timestamp,
  }));
}
export default function AdminTask({ checker }: { checker?: boolean }) {
  const [urlParams] = useSearchParams();
  const taskID = urlParams.get('id');
  const {
    data: { data, history, taskType, maker },
  } = useQuery<QueryResponse>(
    ['getAdminTask', taskID],
    () => axios.get(`${ADMIN_GET_TASK}/${taskID}`, { headers }).then((res) => res.data),
    { initialData }
  );
  return (
    <>
      {taskType === 'ADD' && (
        <Add data={data} checker={checker} history={mapHistory(history)} maker={maker} />
      )}
      {taskType === 'EDIT' && (
        <Edit data={data} checker={checker} history={mapHistory(history)} maker={maker} />
      )}
      {taskType === 'REFRESH' && (
        <Refresh data={data} checker={checker} history={mapHistory(history)} maker={maker} />
      )}
      {taskType === 'DELETE' && (
        <Remove data={data} checker={checker} history={mapHistory(history)} maker={maker} />
      )}
      {taskType === '' && (
        <>
          <Box sx={{ backgroundColor: '#FFF', mb: '16px' }}>
            <Toolbar disableGutters sx={{ paddingX: '32px' }} />
          </Box>
          <Container sx={TaskIDBar}>
            <Typography variant="h1" fontWeight={700}>
              Task ID {taskID}
            </Typography>
          </Container>
          <Container
            sx={{
              bgcolor: '#FFF',
              minWidth: 'calc(100vw - 63px + 1px)',
              minHeight: '400px',
              paddingBottom: '32px',
            }}
          />
        </>
      )}
      <Container sx={containerStyle}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: 0, margin: 0 }}
          >
            <Typography variant="h1">Workflow History</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0, margin: 0 }}>
            <Divider />
            <WorkflowHistory history={mapHistory(history)} />
          </AccordionDetails>
        </Accordion>
      </Container>
    </>
  );
}
AdminTask.defaultProps = {
  checker: false,
};
