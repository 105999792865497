import { CircularProgress, Divider, Grid, Typography } from '@mui/material';
import React from 'react';

export default function LoadingInfo() {
  return (
    <Grid container spacing={2} columns={2}>
      <Grid item xs={2}>
        <Divider />
      </Grid>
      <Grid item width="281px">
        <Typography variant="form_label">Borrower Name</Typography>
      </Grid>
      <Grid item xs>
        <CircularProgress />
      </Grid>
      <Grid item xs={2}>
        <Divider />
      </Grid>
      <Grid item width="281px">
        <Typography variant="form_label">Type of Cases</Typography>
      </Grid>
      <Grid item xs>
        <CircularProgress />
      </Grid>
      <Grid item xs={2}>
        <Divider />
      </Grid>
      <Grid item width="281px">
        <Typography variant="form_label">Type of Document</Typography>
      </Grid>
      <Grid item xs>
        <CircularProgress />
      </Grid>
      <Grid item xs={2}>
        <Divider />
      </Grid>
      <Grid item width="281px">
        <Typography variant="form_label">Upload Files</Typography>
      </Grid>
      <Grid item xs>
        <CircularProgress />
      </Grid>
      <Grid item xs={2}>
        <Divider />
      </Grid>
      <Grid item width="281px">
        <Typography variant="form_label">Insured Name</Typography>
      </Grid>
      <Grid item xs>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}
