import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { NAME_UPDATE_ENDPOINT, PROCESS_ENDPOINT, SUBMIT_ENDPOINT } from '../../urls';
import headers from '../../utils/headers';
import { ValueType } from './interface';

function generateChecklistRequest(
  taskId: string,
  borrowerName: string,
  docType: string,
  topDate: string,
  caseType?: string
) {
  if (caseType === 'new') {
    return axios.post(
      PROCESS_ENDPOINT,
      {
        taskId,
        borrowerName,
        caseType: caseType?.toUpperCase(),
        userInputs: {
          docType,
          topDate,
        },
      },
      { headers }
    );
  }
  return axios.post(
    PROCESS_ENDPOINT,
    {
      taskId,
      borrowerName,
      caseType: caseType?.toUpperCase(),
      userInputs: {
        docType,
      },
    },
    { headers }
  );
}
function updateNameRequest(taskId: string, insuredName: string) {
  return axios.post(
    NAME_UPDATE_ENDPOINT,
    {
      taskId,
      insuredName,
    },
    { headers }
  );
}
function submitFormRequest(taskId: string) {
  return axios.post(
    SUBMIT_ENDPOINT,
    {
      taskId,
    },
    { headers }
  );
}

function submitChecklistRequest(taskId: string, checklistItems: string[]) {
  return axios.post(
    SUBMIT_ENDPOINT,
    {
      taskId,
      collaterals: checklistItems,
    },
    { headers }
  );
}

function submitGcinRequest(
  taskId: string,
  { caseType, borrowerName, docType, gcin, gcinLabel }: ValueType
) {
  return axios.post(
    PROCESS_ENDPOINT,
    {
      taskId,
      borrowerName,
      caseType: caseType?.toUpperCase(),
      userInputs: {
        docType,
        gcin,
        gcinLabel,
      },
    },
    { headers }
  );
}
interface Mutations {
  submitChecklist: any;
  submitDraft: any;
  saveInsuredName: any;
  formSubmission: any;
  submitGcin: any;
}

const handleSubmit = (
  { submitChecklist, submitDraft, saveInsuredName, formSubmission, submitGcin }: Mutations,
  values: ValueType,
  insuredName: string,
  type: string
) => {
  if (type === 'checklist') {
    submitChecklist(values);
  }
  if (type === 'draft') {
    submitDraft(values);
  }
  if (type === 'new') {
    if (insuredName !== values.insuredName) {
      saveInsuredName(values);
    }
    formSubmission();
  }
  if (type === 'renewal') {
    saveInsuredName({ ...values, insuredName }).then(() => {
      submitGcin(values);
    });
  }
};

function submitTitleText(type: string, caseType: string) {
  switch (type) {
    case 'draft':
      return `Generate the ${caseType === 'renewal' ? 'result?' : 'checklist?'}`;
    case 'renewal':
      return 'Generate the checklist?';
    case 'new':
      return 'Submit this case?';
    case 'checklist':
      return 'Submit this case?';
    default:
      return '';
  }
}
export default function SubmitDialog({
  type,
  insuredName,
}: {
  type: string;
  insuredName?: string;
}) {
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();
  const taskId = urlParams.get('id') ?? '';
  const { values, setFieldValue } = useFormikContext<ValueType>();
  const { mutate: submitDraft } = useMutation(
    ({ borrowerName, docType, topDate, caseType }: ValueType) =>
      generateChecklistRequest(taskId, borrowerName, docType, topDate, caseType),
    { onSuccess: () => navigate(`/task/processing/${values.caseType}?id=${taskId}`) }
  );
  const { mutate: submitChecklist } = useMutation(
    ({ checklistItems }: ValueType) => submitChecklistRequest(taskId, checklistItems),
    { onSuccess: () => navigate('/') }
  );
  const { mutate: formSubmission } = useMutation(() => submitFormRequest(taskId), {
    onSuccess: () => navigate('/'),
  });
  const { mutate: submitGcin } = useMutation((v: ValueType) => submitGcinRequest(taskId, v), {
    onSuccess: () => navigate(`/task/processing/${values.caseType}?id=${taskId}&checklist`),
  });
  const { mutateAsync: saveInsuredName } = useMutation(({ insuredName: i }: ValueType) =>
    updateNameRequest(taskId, i)
  );

  const mutations = {
    submitChecklist,
    submitDraft,
    saveInsuredName,
    formSubmission,
    submitGcin,
  };

  return (
    <Dialog
      open={values.openDialog}
      onClose={() => setFieldValue('openDialog', false)}
      fullWidth
      slotProps={{ backdrop: { sx: { backgroundColor: 'rgba(0, 0, 0, 0.7)' } } }}
      PaperProps={{ sx: { maxWidth: '680px', padding: '24px' } }}
    >
      <DialogTitle sx={{ p: 0 }} fontWeight={400} fontSize="24px">
        {submitTitleText(type, values.caseType)}
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        {type !== 'checklist' && (
          <>
            <DialogContentText color="#222222" fontWeight={700} margin="16px 0px 8px 0px">
              {type === 'draft' &&
                `You are going to generate the ${
                  values.caseType === 'Renewal Cases' ? 'result' : 'checklist'
                }`}
              {type === 'renewal' && 'You are going to generate the checklist'}
              {type === 'new' && 'You are going to submit this case'}
              {type === 'checklist' && 'You are going to submit this case'}
            </DialogContentText>
            <DialogContentText color="#222222" marginBottom="64px">
              {type === 'draft' && 'Please make sure that all the entered information is correct.'}
              {type === 'renewal' &&
                "Please make sure you've entered the correct Insured Name and GCIN."}
              {type === 'new' &&
                "Please make sure you've entered the correct Insured Name and Remarks."}
            </DialogContentText>
          </>
        )}
        {type === 'checklist' && (
          <>
            Note that{' '}
            {values.checklistItems.length > 1 ? (
              <List sx={{ listStyleType: "'- '", pl: 1, margin: 0 }}>
                {values.checklistItems.map((string) => (
                  <ListItem
                    key={string}
                    sx={{
                      display: 'list-item',
                      p: 0,
                      maxWidth: '463px',
                    }}
                  >
                    <DialogContentText color="#222222" fontWeight={700} display="inline">
                      {string}
                    </DialogContentText>
                  </ListItem>
                ))}
              </List>
            ) : (
              <DialogContentText color="#222222" fontWeight={700} display="inline">
                {values.checklistItems[0]}
              </DialogContentText>
            )}
            <DialogContentText color="#222222" marginBottom="64px" display="inline">
              {values.checklistItems.length > 1 ? ' are' : ' is'} selected for submission. Please
              make sure you&apos;ve entered the correct Insured Name and remarks.
            </DialogContentText>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setFieldValue('openDialog', false)}>Cancel</Button>
        <Button
          data-testid="submit_button"
          onClick={() => handleSubmit(mutations, values, insuredName ?? '', type)}
          variant="contained"
          sx={{ p: '9px 16px', width: '149px' }}
        >
          <Typography fontWeight={700}>Submit</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SubmitDialog.defaultProps = {
  insuredName: '',
};
