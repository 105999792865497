interface Requirement {
  text: string;
  list?: Array<string | Requirement>;
}

const requirements: Requirement[] = [
  {
    text: `Original/ copies of all insurance certificates or fire policies or cover notes received. (Note: Cover notes are acceptable 
        at activation for assets under Hire Purchase only but original/ copies of policies by brokers/ underwriters must be submitted after loan activation).`,
  },
  {
    text: 'Borrower or asset owner is correctly named as the insured.',
  },
  {
    text: "DBS's interest is correctly noted in the policy as Mortgagee/ Chargee/ DBS as owner & borrower as hirer (for hire purchase) / Security  Trustee/ lender.",
  },
  {
    text: 'Subject matter of insurance/serial & model nos. of machinery/ location of risk are correctly stated in policy.',
  },
  {
    text: 'Commencement date of cover must be on or before:',
    list: [
      'TOP date or Loan disbursement date, whichever is later (new case)',
      'Expiry of previous coverage period (renewal cases)',
    ],
  },
  {
    text: 'Insurer must be acceptable (please refer to Section 1.2.1 of the guidelines)',
  },
  {
    text: 'Type of cover: ',
    list: [
      'Fire and Extraneous Perils (Narrowest cover);',
      'Machinery or Industrial All Risks;',
      'Industrial Special Risks (Broadest cover); or',
      'Mortgagee Interest Policy (for property under MCST).',
    ],
  },
  {
    text: 'Any monies in respect of loss or damage is payable to DBS.',
  },
  {
    text: 'At least a 14-day notice to be given to DBS prior to cancellation by the insurer(s).',
  },
  {
    text: 'Insurer to advise DBS prior to cancellation of policy if insurer receives instruction for policy cancellation',
  },
  {
    text: 'Sum Insured must not be less than:',
    list: [
      {
        text: 'For Fire and Extraneous Perils (Narrowest cover):',
        list: [
          'Fire policy: fire reinstatement value',
          "Mortgagees' Interest (for Strata Title - MCST): the fire reinstatement value or facility limits/ amount outstanding (whichever is lower)",
        ],
      },
      'Machinery/Equipment: depreciated/fair market value or purchase price (where applicable)',
      'Fixed and Floating Charge on Assets: Net Book Value for the asset insured.',
      'Latest relevant values to be used.',
    ],
  },
];

export default requirements;
