import { Container, MenuItem, Stack, Typography, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ErrorMessage, useFormikContext } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { Form, FormRow } from '../../../components/Form';
import { FileAttachment } from '../../../components/Form/Attachment';
import Dropzone from '../../../components/Form/Dropzone';
import SelectInput from '../../../components/Form/Select';
import { ADMIN_FILE_ENDPOINT } from '../../../urls';
import MakerDialog from '../Dialog';
import { getChecklistItems } from '../StaticListTable';

// Constants and Types
const INPUT_WIDTH = '348px';
// Sub Components

const ErrorMessageComp = styled(ErrorMessage)(() => ({
  color: '#CC0000',
}));

// Form Component

interface AddFormValue {
  checklistItem: string;
  documentName: string;
  lookupName: string;
  filesStatus: string;
  filesUploaded: string;
  openDialog: boolean;
}
interface IChecklistItems {
  checkListItem: string;
  locked: boolean;
  metadata: {
    [index: string]: string[];
  };
  description: string;
}
export default function Refresh() {
  const { values, handleChange, setFieldValue } = useFormikContext<AddFormValue>();
  const { data } = useQuery<IChecklistItems[]>(['ChecklistItems'], () => getChecklistItems(), {
    initialData: [],
  });
  const lockedItems = data.filter((item) => item.locked === false);
  const nonLockedItems = data.filter(
    (item) => values.checklistItem === item.checkListItem && item.locked
  );
  const checklistItems = [...lockedItems, ...nonLockedItems].sort(
    (a, b) => a.checkListItem.charCodeAt(0) - b.checkListItem.charCodeAt(0)
  );
  const documentNames = Object.keys(
    data.filter((item) => item.checkListItem === values.checklistItem)?.[0]?.metadata ?? {}
  );
  const lookupNames =
    data.filter((item) => item.checkListItem === values.checklistItem)[0]?.metadata[
      values.documentName ?? ''
    ] ?? [];

  const [urlParams] = useSearchParams();
  const taskId = urlParams.get('id') ?? '';

  return (
    <Container sx={{ bgcolor: '#FFF', minWidth: 'calc(100vw - 64px)', paddingBottom: '32px' }}>
      <Stack direction="row" justifyContent="flex-start" paddingY="16px">
        <Typography variant="h1" marginRight="8px">
          Refresh Keywords
        </Typography>
      </Stack>
      <Form formId="maker-refresh-form">
        <FormRow label="Checklist Item">
          <SelectInput
            value={values.checklistItem}
            name="checklistItem"
            handleChange={handleChange}
            renderValue={(v) =>
              v
                ? `${checklistItems?.filter((t) => t.checkListItem === v)?.[0]?.description}`
                : 'Select'
            }
            width={INPUT_WIDTH}
          >
            {checklistItems.map((item) => (
              <MenuItem key={item.checkListItem} value={item.checkListItem}>
                {item.description}
              </MenuItem>
            ))}
          </SelectInput>
          <ErrorMessageComp name="checklistItem" component={Typography} />
        </FormRow>
        <FormRow label="Document Name">
          <SelectInput
            value={values.documentName}
            name="documentName"
            handleChange={handleChange}
            renderValue={(v) => v || 'Select'}
            width={INPUT_WIDTH}
          >
            {documentNames.map((documentName) => (
              <MenuItem key={documentName} value={`${documentName}`}>
                {documentName}
              </MenuItem>
            ))}
          </SelectInput>
          <ErrorMessageComp name="documentName" component={Typography} />
        </FormRow>
        <FormRow label="Lookup Name">
          <SelectInput
            value={values.lookupName}
            name="lookupName"
            handleChange={handleChange}
            renderValue={(v) => v || 'Select'}
            width={INPUT_WIDTH}
          >
            {lookupNames.map((lookup) => (
              <MenuItem key={lookup} value={lookup}>
                {lookup}
              </MenuItem>
            ))}
          </SelectInput>
          <ErrorMessageComp name="lookupName" component={Typography} />
        </FormRow>
        <FormRow label="Upload Files">
          {values.filesUploaded && (
            <FileAttachment
              filename={values.filesUploaded}
              status={values.filesStatus}
              getEndpoint={`${ADMIN_FILE_ENDPOINT}?taskId=${taskId}&filename=${encodeURIComponent(
                values.filesUploaded
              )}`}
              width={INPUT_WIDTH}
              handleDelete={() => {
                setFieldValue('filesUploaded', '');
                setFieldValue('filesStatus', '');
              }}
            />
          )}
          <Dropzone
            taskId={taskId}
            filesStatus={values.filesStatus}
            files={values.filesUploaded}
            uploadUrl={ADMIN_FILE_ENDPOINT}
          />
          <ErrorMessageComp name="keyword" component={Typography} />
        </FormRow>
        <MakerDialog taskType="REFRESH" />
      </Form>
    </Container>
  );
}
