import { Box, Button, Toolbar, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CREATE_ENDPOINT } from '../../urls';
import headers from '../../utils/headers';
import AdminToolbar from './AdminToolbar';
import CheckerToolbar from './ChekerToolbar';
import MakerToolbar from './MakerToolbar';
import CreateTaskToolbar from './TaskToolbar';

/*
This is the default toolbar for Maker view. 
*/
export default function DefaultToolbar() {
  const navigate = useNavigate();
  const { mutate } = useMutation({
    mutationFn: () => axios.put(CREATE_ENDPOINT, null, { headers }).then((res) => res.data),
    onSuccess: ({ taskId }) => navigate(`/task/create?id=${taskId}`),
  });
  return (
    <Box sx={{ backgroundColor: '#FFF', mb: '16px' }}>
      <Toolbar disableGutters sx={{ paddingX: '32px' }}>
        <Typography variant="toolbar_h1" sx={{ flexGrow: 1 }}>
          Insurance Contract Reader
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => mutate()}
          sx={{
            py: '9px',
            minWidth: '128px',
          }}
        >
          <Typography fontWeight={700}>Create New Task</Typography>
        </Button>
      </Toolbar>
    </Box>
  );
}

export { AdminToolbar, CheckerToolbar, CreateTaskToolbar, MakerToolbar };
