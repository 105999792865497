import { ArrowBack, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Container,
  Divider,
  SxProps,
  Typography,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import CreateTaskToolbar from '../../components/toolbars/TaskToolbar';
import ViewToolbar from '../../components/toolbars/ViewToolbar';
import WorkflowHistory from '../../components/workflowHistory';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  ICheckBox,
  setCollateralCheckBoxes,
  updateCollateralCheckBoxes,
} from '../../redux/taskStore';
import { GET_ENDPOINT } from '../../urls';
import headers from '../../utils/headers';
import SubmitDialog from './SubmitDialog';
import CreateForm from './create/Form';
import { IChecklistArray, IQueryResponse, ValueType } from './interface';
import { draftValidation, nameValidationNew, nameValidationRenewal } from './validations';
import TaskInfo from './view';
import ChecklistHeader from './view/checklistHeader';
import ChecklistTable from './view/checklistTable';
import DefaultView from './view/default';
import LoadingInfo from './view/loading';

const containerStyle: SxProps = {
  bgcolor: '#FFF',
  minWidth: 'calc(100vw - 64px)',
  marginY: '8px',
  paddingTop: '16px',
  paddingBottom: '32px',
};

const Accordian = { ...containerStyle, paddingTop: '4px', paddingBottom: '12px' };
interface Schema {
  [key: string]: Yup.ObjectSchema<any> | undefined;
}
const validationSchema: Schema = {
  draft: draftValidation,
  new: nameValidationNew,
  renewal: nameValidationRenewal,
  checklist: undefined,
};

const TaskIDBar = {
  bgcolor: '#FFF',
  minWidth: 'calc(100vw - 64px)',
  marginY: '8px',
  paddingY: '16px',
};

const taskCompletePath = '/task/completed';
function getMatches(checklist: IChecklistArray) {
  return `${
    checklist?.assetChecklist?.filter((item) => item.complied === 'COMPLIED')?.length
  } out of 
  ${
    checklist?.assetChecklist?.filter((item) => item.complied !== 'NOT APPLICABLE')?.length
  } matches`;
}
function Checklist({
  type,
  data,
  isLoading,
}: {
  type: 'new' | 'renewal';
  data: IQueryResponse;
  isLoading: boolean;
}) {
  const dispatch = useAppDispatch();
  const view = useAppSelector((state) => state.taskStore.authLevel === 1);
  const collateralCheckBoxes = useAppSelector(
    (state) => state.taskStore.collateralCheckBoxes
  ) as ICheckBox;
  useEffect(() => {
    if (isLoading || !data?.metadata.collateralData) {
      return;
    }
    dispatch(
      setCollateralCheckBoxes(
        Object.keys(data.metadata.collateralData).reduce((obj: ICheckBox, key: string) => {
          obj[key] = false;
          return obj;
        }, {})
      )
    );
  }, [isLoading, data, dispatch]);
  const rawChecklist = data?.checklist ?? [];
  rawChecklist.sort(
    (a, b) =>
      b.assetChecklist.filter((item) => item.complied === 'COMPLIED').length -
      a.assetChecklist.filter((item) => item.complied === 'COMPLIED').length
  );
  const ChecklistItems = rawChecklist
    .filter((item) => Object.keys(data.metadata.collateralData ?? {}).includes(item.assetId))
    .map((item) => item.assetId);

  const handleCollateralCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateCollateralCheckBoxes(event.target));
  };

  const getChecklistByAssetId = (assetId: IChecklistArray[keyof IChecklistArray]) =>
    data?.checklist.find((item) => item.assetId === assetId);
  const location = useLocation();
  if (isLoading) {
    return null;
  }
  if (type === 'new') {
    return (
      <Container sx={Accordian}>
        <Accordion elevation={0} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: 0, margin: 0, mb: -1 }}
          >
            <Typography variant="h1">Checklist</Typography>
            <Typography
              fontWeight={600}
              fontSize="12px"
              lineHeight="16px"
              sx={{
                color: '#915D00',
                bgcolor: '#FFEBC8',
                p: '2px 4px',
                borderRadius: '2px',
                m: 'auto 8px',
              }}
            >
              {getMatches(data?.checklist[0])}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0, margin: 0 }}>
            <Divider />
            <ChecklistTable
              checklist={data?.checklist[0]}
              maker={data?.maker}
              uploadTime={data?.uploadTime}
            />
          </AccordionDetails>
        </Accordion>
      </Container>
    );
  }
  return (
    <>
      {data?.checklist !== null &&
        data?.metadata.collateralData !== null &&
        ChecklistItems?.map((collateralId, index) => (
          <Container key={collateralId} sx={Accordian}>
            <Accordion elevation={0} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ padding: 0, margin: 0, mb: -1 }}
              >
                {!location.pathname.startsWith(taskCompletePath) && !view ? (
                  <FormControlLabel
                    onClick={(e) => e.stopPropagation()}
                    key={collateralId}
                    control={
                      <Checkbox
                        checked={
                          collateralCheckBoxes === null ? false : collateralCheckBoxes[collateralId]
                        }
                        name={collateralId}
                        sx={{
                          color: '#999999',
                        }}
                        onChange={handleCollateralCheckBoxClick}
                      />
                    }
                    label={
                      <Typography variant="checklist_header_h1">
                        {collateralId} - {data.metadata.collateralData[collateralId].collateralType}
                      </Typography>
                    }
                  />
                ) : (
                  <Typography variant="checklist_header_h1">
                    {collateralId} - {data.metadata.collateralData[collateralId].collateralType}
                  </Typography>
                )}
                <Typography
                  fontWeight={600}
                  fontSize="12px"
                  lineHeight="16px"
                  sx={{
                    color: '#915D00',
                    bgcolor: '#FFEBC8',
                    p: '2px 4px',
                    borderRadius: '2px',
                    m: 'auto 8px',
                  }}
                >
                  {getMatches(data?.checklist[index])}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0, margin: 0 }}>
                <Divider />

                <ChecklistHeader
                  collateralId={collateralId}
                  allCollateralData={data?.metadata?.collateralData}
                  gcinLabel={data?.userInputs?.gcinLabel}
                />
                {getChecklistByAssetId(collateralId) && (
                  <ChecklistTable
                    key={collateralId}
                    checklist={getChecklistByAssetId(collateralId)}
                    maker={data?.maker}
                    uploadTime={data?.uploadTime}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Container>
        ))}
      {/* Do not remove, This comment it to force render as JSX instead of boolean or JSX */}
    </>
  );
}
function initialValues(data: IQueryResponse, trueKeys: string[], caseType: string) {
  return {
    borrowerName: data?.borrowerName ?? '',
    caseType: data?.caseType?.toLowerCase() ?? caseType,
    docType: data?.userInputs?.docType ?? '',
    topDate: data?.userInputs?.topDate ?? new Date().toISOString(),
    filesUploaded: data?.files?.map((file) => file.filename) ?? [],
    insuredName: data?.userInputs?.selectedInsured ?? data?.metadata?.insuredName,
    gcinLabel: data?.userInputs?.gcinLabel ?? '',
    gcin: data?.userInputs?.gcin ?? '',
    filesStatus: data?.files?.map(() => 'success') ?? [],
    checklistItems: trueKeys ?? '',
    openDialog: false,
    selectedInsured: data?.userInputs?.selectedInsured,
  } as ValueType;
}

function getSchemaKey(data: IQueryResponse, type: 'new' | 'renewal', draft?: boolean) {
  if (type === 'new' && !draft) {
    return 'new';
  }
  if (data?.checklist && !draft && type === 'renewal') {
    return 'checklist';
  }
  if (type === 'renewal' && !draft && data?.checklist === null) {
    return 'renewal';
  }
  return 'draft';
}
export default function Task({ type, draft }: { type: 'new' | 'renewal'; draft?: boolean }) {
  const [urlParams] = useSearchParams();
  const location = useLocation();
  const view = useAppSelector((state) => state.taskStore.authLevel === 1);
  const id = urlParams.get('id');
  const caseType = useAppSelector((state) => state.taskStore.caseType)
    .split(' ')[0]
    .toLowerCase();
  const { data, isLoading } = useQuery<IQueryResponse>(['getTask', id], () =>
    axios.get(`${GET_ENDPOINT}/${id}`, { headers }).then((res) => res.data)
  );
  const history = data?.history ?? [{ name: '', action: '', date: new Date().toISOString() }];
  const collateralCheckBoxes = useAppSelector(
    (state) => state.taskStore.collateralCheckBoxes
  ) as ICheckBox;
  const trueKeys =
    collateralCheckBoxes !== null
      ? Object.keys(collateralCheckBoxes).filter((key) => collateralCheckBoxes[key])
      : [];
  const schemaKey = getSchemaKey(data as IQueryResponse, type, draft);
  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={validationSchema[schemaKey as keyof typeof validationSchema]}
        validateOnMount
        initialValues={initialValues(data as IQueryResponse, trueKeys, caseType)}
        onSubmit={(value, { setFieldValue }) => setFieldValue('openDialog', true)}
      >
        <>
          {!view ? (
            <CreateTaskToolbar
              history={history?.[history.length - 1]}
              type={schemaKey}
              insuredName={data?.metadata?.insuredName}
            />
          ) : (
            <ViewToolbar>
              <Button component={Link} startIcon={<ArrowBack />} sx={{ color: '#000' }} to="/">
                <Typography fontWeight={600} fontSize="16px" lineHeight="16px" noWrap width="75px">
                  Task List
                </Typography>
              </Button>
            </ViewToolbar>
          )}
          <Container sx={TaskIDBar}>
            <Typography variant="h1" fontWeight={700}>
              Task ID {id}
            </Typography>
          </Container>
          <Container sx={containerStyle}>
            <Typography variant="h1" marginBottom="16px">
              Insurance Document
            </Typography>
            {isLoading && <LoadingInfo />}
            {(view || location.pathname.startsWith(taskCompletePath)) && !isLoading && (
              <DefaultView />
            )}
            {!(view || location.pathname.startsWith(taskCompletePath)) &&
              !isLoading &&
              (schemaKey === 'draft' ? (
                <CreateForm />
              ) : (
                <TaskInfo type={schemaKey} insuredName={data?.metadata?.insuredName ?? ''} />
              ))}
          </Container>
          <SubmitDialog type={schemaKey} insuredName={data?.metadata?.insuredName} />
        </>
      </Formik>
      {data?.checklist !== null && !isLoading && !draft && (
        <Checklist type={type} data={data as IQueryResponse} isLoading={isLoading} />
      )}
      <Container sx={Accordian}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: 0, margin: 0 }}
          >
            <Typography variant="h1">Workflow History</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0, margin: 0 }}>
            <Divider />
            <WorkflowHistory history={history} />
          </AccordionDetails>
        </Accordion>
      </Container>
    </>
  );
}
Task.defaultProps = {
  draft: false,
};
