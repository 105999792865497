import { Button, Container, Grid, MenuItem, Stack, Typography, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { Form, FormRow } from '../../../components/Form';
import SelectInput from '../../../components/Form/Select';
import TextBox from '../../../components/Form/Textbox';
import CheckerDialog from '../Dialog';
import { getChecklistItems } from '../StaticListTable';

// Constants and Types
const INPUT_WIDTH = '348px';
const buttonStyle = {
  bgcolor: '#EEEEEE',
  color: '#000000',
  ':disabled': { bgcolor: '#cccccc', color: '#FFF', boxShadow: 2 },
  p: '9px 16px',
  minWidth: '128px',
};
// Sub Components

const ErrorMessageComp = styled(ErrorMessage)(() => ({
  color: '#CC0000',
}));
// Form Component

interface AddFormValue {
  value: {
    checklistItem: string;
    documentName: string;
    lookupName: string;
    keyword: string;
  }[];
  openDialog: boolean;
}
interface IChecklistItems {
  checkListItem: string;
  locked: boolean;
  metadata: {
    [index: string]: string[];
  };
  description: string;
}
export default function Add({ x }: { x: number }) {
  const { values, handleChange } = useFormikContext<AddFormValue>();
  const [keywords, setKeywords] = React.useState(0 + x);
  const { data } = useQuery<IChecklistItems[]>(['ChecklistItems'], () => getChecklistItems(), {
    initialData: [],
  });
  const valueItems = values?.value?.map((v) => v.checklistItem);
  const lockedItems = data.filter((item) => item.locked === false);
  const nonLockedItems = data.filter(
    (item) => valueItems?.includes(item.checkListItem) === true && item.locked === true
  );
  const checklistItems = [...lockedItems, ...nonLockedItems].sort(
    (a, b) => a.checkListItem.charCodeAt(0) - b.checkListItem.charCodeAt(0)
  );

  const documentNames = (index: number) =>
    Object.keys(
      data?.filter((item) => item.checkListItem === values?.value?.[index]?.checklistItem)[0]
        ?.metadata ?? {}
    );
  const lookupNames = (index: number) =>
    data.filter((item) => item.checkListItem === values?.value?.[index]?.checklistItem)[0]
      ?.metadata[values?.value?.[index]?.documentName ?? ''] ?? [];
  return (
    <Container sx={{ bgcolor: '#FFF', minWidth: 'calc(100vw - 64px)', paddingBottom: '32px' }}>
      <Stack direction="row" justifyContent="space-between" paddingY="16px" marginBottom="16px">
        <Typography variant="h1" marginRight="8px">
          Add Keywords
        </Typography>
        <Button
          variant="contained"
          color="inherit"
          sx={buttonStyle}
          onClick={() => setKeywords(keywords + 1)}
        >
          <Typography fontWeight={700}>Add More Keywords</Typography>
        </Button>
      </Stack>
      <Form formId="add-form">
        <FieldArray
          name="values"
          render={() =>
            Array.from(Array(keywords > 0 ? keywords : 1).keys()).map((value, index) => (
              <Grid
                container
                spacing={2}
                columns={2}
                key={`${JSON.stringify(value)}`}
                sx={{ marginBottom: '32px', paddingX: '16px' }}
              >
                <FormRow label="Checklist Item" topBorder={Boolean(index !== 0)}>
                  <SelectInput
                    name={`value[${index}].checklistItem`}
                    value={values.value?.[index]?.checklistItem ?? ''}
                    handleChange={handleChange}
                    width={INPUT_WIDTH}
                    renderValue={(v) =>
                      v
                        ? `${
                            checklistItems?.filter((t) => t.checkListItem === v)?.[0]?.description
                          }`
                        : 'Select'
                    }
                  >
                    {checklistItems.map((item) => (
                      <MenuItem key={item.checkListItem} value={item.checkListItem}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </SelectInput>
                  <ErrorMessageComp name={`value[${index}].checkListItem`} component={Typography} />
                </FormRow>
                <FormRow label="Document Name">
                  <SelectInput
                    name={`value[${index}].documentName`}
                    handleChange={handleChange}
                    value={values.value?.[index]?.documentName ?? ''}
                    renderValue={(v) => v || 'Select'}
                    width={INPUT_WIDTH}
                  >
                    {documentNames(index).map((documentName) => (
                      <MenuItem key={documentName} value={`${documentName}`}>
                        {documentName}
                      </MenuItem>
                    ))}
                  </SelectInput>
                  <ErrorMessageComp name={`value[${index}].documentName`} component={Typography} />
                </FormRow>
                <FormRow label="Lookup Name">
                  <SelectInput
                    name={`value[${index}].lookupName`}
                    handleChange={handleChange}
                    value={values?.value?.[index]?.lookupName ?? ''}
                    renderValue={(v) => v || 'Select'}
                    width={INPUT_WIDTH}
                  >
                    {lookupNames(index).map((lookup) => (
                      <MenuItem key={lookup} value={lookup}>
                        {lookup}
                      </MenuItem>
                    ))}
                  </SelectInput>
                  <ErrorMessageComp name={`value[${index}].lookupName`} component={Typography} />
                </FormRow>
                <FormRow label="Keyword">
                  <TextBox
                    name={`value[${index}].keyword`}
                    values={values?.value?.[index]?.keyword ?? ''}
                    width={INPUT_WIDTH}
                    placeholder="Keyword"
                  />
                  <ErrorMessageComp name="keyword" component={Typography} />
                </FormRow>
              </Grid>
            ))
          }
        />
        <CheckerDialog taskType="ADD" />
      </Form>
    </Container>
  );
}
