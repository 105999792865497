import { CircularProgress, Stack, Typography } from '@mui/material';
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import Appbar from '../components/global/Appbar';
import { useAppDispatch } from '../redux/hooks';
import { setAuthLevel, setUsername } from '../redux/taskStore';
import { LOGIN_REDIRECT_URL } from '../urls';

interface IToken {
  user_name: string;
  displayName: string;
  scope: string[];
  departmentCode: number;
  securityGroups: string[];
  authorities: string[];
  exp: number;
}
export default function Session() {
  const [loading, setLoading] = useState(true);
  sessionStorage.setItem(
    'access_token',
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJ1cGRhdGUiLCJkaXNwbGF5TmFtZSI6InVwZGF0ZSIsInNjb3BlIjpbIm9wZW5pZCJdLCJkZXBhcnRtZW50Q29kZSI6IjA0NzkiLCJzZWN1cml0eUdyb3VwcyI6WyIwMVVSRUcxX0dDQUFfSU5TX0NIRUNLTElTVF9VUERBVEUiXSwiYXV0aG9yaXRpZXMiOlsiUk9MRV9VU0VSIl0sImV4cCI6MTc3NTA3NzM5MH0.EVaDeYsA7avvh72BgppK3Rd96UsFgh90PND1Yyt6F26buVk1vCX6VNbAbRACcS4Ljl4i_5e0jJlqghiP-Ppg0OCrjzCHrPaq-6l-MUDajOCiyXnCDHTHyzovLxYEmKt1_rHKOPnE0tcVw6lrPtPLFQlcLoC_F9qIs7VvGq57dfi29UztgtYb9_uGUgl_hyoM3tko3f7UgiROW7WejKQTwbAcbE8p7a0ghcx7V7q0dgwKm8s_lh0NA-2nBE2ijo5blU7sFDsFlcsCE-WTg3B7HOK4Ba9jobNssz4_HX2D3S6_LmoUq5k_Z4QJBbQcvTHt-OjOAQnzFjpEX6BzZkapyA'
  );
  const token = sessionStorage.getItem('access_token');
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!token) {
      window.location.replace(LOGIN_REDIRECT_URL);
    }
    if (token) {
      const { securityGroups, user_name: userName } = jwt_decode<IToken>(token);
      dispatch(setUsername(userName));
      if (securityGroups.includes('01UREG1_GCAA_INS_CHECKLIST_UPDATE')) {
        dispatch(setAuthLevel(2));
        setLoading(false);
      }
      if (securityGroups.includes('01UREG1_GCAA_INS_CHECKLIST_VIEW')) {
        dispatch(setAuthLevel(1));
        setLoading(false);
      }
    }
  }, [token, setLoading, dispatch]);
  if (loading) {
    return (
      <Stack minHeight="100vh" justifyContent="center" alignItems="center">
        <CircularProgress />
        <Typography fontSize="24px" fontWeight={600} marginTop="40px">
          Loading
        </Typography>
      </Stack>
    );
  }
  return (
    <>
      <Appbar />
      <Outlet />
    </>
  );
}
