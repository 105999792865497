interface FilterQuery {
  filterBy: string;
  filterType: string;
  filterValue: string;
}

enum SortType {
  ASC = 'asc',
  DESC = 'desc',
}
interface SortQuery {
  sortBy: string;
  sortType: SortType;
}
export type THandleSortClickFunction = (
  event: React.MouseEvent<HTMLButtonElement>,
  index: number,
  setAnchorSort: React.Dispatch<React.SetStateAction<(HTMLElement | null)[]>>,
  anchorSort: (HTMLElement | null)[]
) => void;
export type TSubmitFunction = (
  filter: FilterQuery[],
  filterType: string,
  filterValue: string,
  index: number,
  setFilter: React.Dispatch<React.SetStateAction<FilterQuery[]>>,
  setFilterQuery: React.Dispatch<React.SetStateAction<FilterQuery[]>>
) => void;
export type THandleCloseFunction = (
  index: number,
  anchorSort: (HTMLElement | null)[],
  setAnchorSort: React.Dispatch<React.SetStateAction<(HTMLElement | null)[]>>,
  setFilterAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>,
  setSort: React.Dispatch<React.SetStateAction<(SortType | null)[]>>,
  setSortQuery: React.Dispatch<React.SetStateAction<SortQuery[]>>,
  sortType?: SortType
) => void;
export type StatusChangeFunction = (
  buttonStatus: number,
  setStatus: React.Dispatch<React.SetStateAction<number>>,
  setPage: React.Dispatch<React.SetStateAction<number>>
) => void;
const handleStatusClick: StatusChangeFunction = (buttonStatus, setStatus, setPage) => {
  setStatus(buttonStatus);
  setPage(0);
};
const submitFunction: TSubmitFunction = (
  filter,
  filterType,
  filterValue,
  index,
  setFilter,
  setFilterQuery
) => {
  let filterBy = '';
  if (index === 0) {
    filterBy = 'borrowerName';
  }
  if (index === 1) {
    filterBy = 'maker';
  }
  if (index === 2) {
    filterBy = 'checker';
  }
  if (index === 3) {
    filterBy = 'statusText';
  }
  const newFilter = filter;

  newFilter[index] = {
    filterBy,
    filterType,
    filterValue,
  };
  setFilter(newFilter);
  setFilterQuery(newFilter.filter((a) => a !== null));
};
const handleSortClick: THandleSortClickFunction = (event, index, setAnchorSort, anchorSort) => {
  const newAnchorSort = [...anchorSort];
  newAnchorSort[index] = event.currentTarget;
  setAnchorSort(newAnchorSort);
};
const handleCloseFunction: THandleCloseFunction = (
  index,
  anchorSort,
  setAnchorSort,
  setFilterAnchorEl,
  setSort,
  setSortQuery,
  sortType
) => {
  const newAnchorSort = [...anchorSort];
  newAnchorSort[index] = null;
  setAnchorSort(newAnchorSort);
  setFilterAnchorEl(null);
  if (sortType) {
    const newSort: Array<SortType | null> = [null, null, null, null];
    newSort[index] = sortType;
    setSort(newSort);
    const idSortQuery = newSort[0] && {
      sortBy: 'id',
      sortType: newSort[0],
    };
    const makerSortQuery = newSort[1] && {
      sortBy: 'maker',
      sortType: newSort[1],
    };
    const checkerSortQuery = newSort[2] && {
      sortBy: 'checker',
      sortType: newSort[2],
    };
    const statusSortQuery = newSort[3] && {
      sortBy: 'statusText',
      sortType: newSort[3],
    };
    const newSortQuery = [idSortQuery, makerSortQuery, checkerSortQuery, statusSortQuery].filter(
      (s) => s
    ) as SortQuery[];

    setSortQuery(newSortQuery);
  }
};
export type ToggleSortFunction = (
  index: number,
  currentSort: SortType | null,
  setSort: React.Dispatch<React.SetStateAction<(SortType | null)[]>>,
  setSortQuery: React.Dispatch<React.SetStateAction<SortQuery[]>>
) => void;

const handleToggleSort: ToggleSortFunction = (index, currentSort, setSort, setSortQuery) => {
  const newSort: Array<SortType | null> = [null, null, null, null];
  if (currentSort === SortType.DESC) {
    newSort[index] = SortType.ASC;
  } else if (currentSort === SortType.ASC) {
    newSort[index] = null;
  } else {
    newSort[index] = SortType.DESC;
  }
  setSort(newSort);
  const idSortQuery = newSort[0] && {
    sortBy: 'id',
    sortType: newSort[0],
  };
  const makerSortQuery = newSort[1] && {
    sortBy: 'maker',
    sortType: newSort[1],
  };
  const checkerSortQuery = newSort[2] && {
    sortBy: 'checker',
    sortType: newSort[2],
  };
  const statusSortQuery = newSort[3] && {
    sortBy: 'statusText',
    sortType: newSort[3],
  };
  const newSortQuery = [idSortQuery, makerSortQuery, checkerSortQuery, statusSortQuery].filter(
    (s) => s
  ) as SortQuery[];
  setSortQuery(newSortQuery);
};
export {
  handleCloseFunction,
  handleSortClick,
  handleStatusClick,
  handleToggleSort,
  submitFunction,
};
