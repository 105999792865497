import { Box, Button, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router';
import { ADMIN_CREATE_ENDPOINT } from '../../urls';
import headers from '../../utils/headers';
import { activeButtonStyle } from './utils';
import { useAppSelector } from '../../redux/hooks';

export default function AdminToolbar() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const view = useAppSelector((state) => state.taskStore.authLevel === 1);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const { mutate } = useMutation(
    (action: string) =>
      axios
        .put(`${ADMIN_CREATE_ENDPOINT}?taskType=${action}`, null, { headers })
        .then((res) => res.data),
    {
      onSuccess: ({ taskId }) => {
        navigate(`/admin/maker/?id=${taskId}`);
      },
    }
  );

  return (
    <Box sx={{ backgroundColor: '#FFF', mb: '16px' }}>
      <Toolbar disableGutters sx={{ paddingX: '32px' }}>
        <Typography variant="toolbar_h1" sx={{ flexGrow: 1 }}>
          Admin Screen
        </Typography>
        {!view && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            sx={{
              py: '9px',
              ...activeButtonStyle(true),
            }}
          >
            <Typography fontWeight={700}>Start an Activity</Typography>
          </Button>
        )}
      </Toolbar>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        elevation={1}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => mutate('ADD')}>Add Keywords</MenuItem>
        <MenuItem onClick={() => mutate('REFRESH')}>Refresh Keywords</MenuItem>
        <MenuItem onClick={() => mutate('EDIT')}>Edit Keywords</MenuItem>
        <MenuItem onClick={() => mutate('DELETE')}>Remove Keywords</MenuItem>
      </Menu>
    </Box>
  );
}
