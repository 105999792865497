const BASE_WORKFLOW_URL = `${process.env.REACT_APP_API_WORKFLOW}/api/v1`;
const CREATE_ENDPOINT = `${BASE_WORKFLOW_URL}/workflow/task/create`;
const DISCARD_ENDPOINT = `${BASE_WORKFLOW_URL}/workflow/task/discard`;
const DRAFT_ENDPOINT = `${BASE_WORKFLOW_URL}/workflow/task/draft`;
const GET_ENDPOINT = `${BASE_WORKFLOW_URL}/workflow/task`;
const LIST_ENDPOINT = `${BASE_WORKFLOW_URL}/workflow/task/list`;
const PROCESS_ENDPOINT = `${BASE_WORKFLOW_URL}/workflow/task/process`;
const NAME_UPDATE_ENDPOINT = `${BASE_WORKFLOW_URL}/workflow/task/update/name`;
const SUBMIT_ENDPOINT = `${BASE_WORKFLOW_URL}/workflow/task/submit`;
const REMARKS_CREATE_ENDPOINT = `${BASE_WORKFLOW_URL}/workflow/remark`;
const REMARKS_UPDATE_ENDPOINT = `${BASE_WORKFLOW_URL}/workflow/remark/update`;

const ADMIN_TASK_LIST = `${BASE_WORKFLOW_URL}/admin/task/list`;
const ADMIN_CREATE_ENDPOINT = `${BASE_WORKFLOW_URL}/admin/task/create`;
const ADMIN_GET_KEYWORDLIST = `${BASE_WORKFLOW_URL}/admin/keyword/list`;
const ADMIN_SAVE_TASK = `${BASE_WORKFLOW_URL}/admin/task/draft`;
const ADMIN_SUBMIT_TASK = `${BASE_WORKFLOW_URL}/admin/task/submit`;
const ADMIN_REJECT_TASK = `${BASE_WORKFLOW_URL}/admin/task/reject`;
const ADMIN_APPROVE_TASK = `${BASE_WORKFLOW_URL}/admin/task/approve`;
const ADMIN_RETURN_TASK = `${BASE_WORKFLOW_URL}/admin/task/return`;
const ADMIN_GET_TASK = `${BASE_WORKFLOW_URL}/admin/task`;
const ADMIN_LIST_CHECKLIST = `${BASE_WORKFLOW_URL}/admin/checklistItem/list`;

const BASE_UPLOAD_URL = `${process.env.REACT_APP_API_UPLOAD}/api/v1`;
const TASK_FILE_ENDPOINT = `${BASE_UPLOAD_URL}/files/tasks`;
const REMARK_FILE_ENDPOINT = `${BASE_UPLOAD_URL}/files/remarks`;
const ADMIN_FILE_ENDPOINT = `${BASE_UPLOAD_URL}/files/admin`;

const BASE_CLS_URL = `${process.env.REACT_APP_API_CLS}/api/v1`;
const SEARCH_CLS_ENDPOINT = `${BASE_CLS_URL}/cls/entitysearch`;

const LOGIN_REDIRECT_URL = `${process.env.REACT_APP_API_CWB}/login`;

export {
  ADMIN_APPROVE_TASK,
  ADMIN_CREATE_ENDPOINT,
  ADMIN_FILE_ENDPOINT,
  ADMIN_GET_KEYWORDLIST,
  ADMIN_GET_TASK,
  ADMIN_LIST_CHECKLIST,
  ADMIN_REJECT_TASK,
  ADMIN_RETURN_TASK,
  ADMIN_SAVE_TASK,
  ADMIN_SUBMIT_TASK,
  ADMIN_TASK_LIST,
  CREATE_ENDPOINT,
  DISCARD_ENDPOINT,
  DRAFT_ENDPOINT,
  GET_ENDPOINT,
  LIST_ENDPOINT,
  LOGIN_REDIRECT_URL,
  NAME_UPDATE_ENDPOINT,
  PROCESS_ENDPOINT,
  REMARKS_CREATE_ENDPOINT,
  REMARKS_UPDATE_ENDPOINT,
  REMARK_FILE_ENDPOINT,
  SEARCH_CLS_ENDPOINT,
  SUBMIT_ENDPOINT,
  TASK_FILE_ENDPOINT,
};
