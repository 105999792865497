import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid, Stack, Toolbar, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useFormikContext } from 'formik';
import { NavigateFunction, useLocation, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { ValueType } from '../../pages/task/interface';
import { DISCARD_ENDPOINT, DRAFT_ENDPOINT, NAME_UPDATE_ENDPOINT } from '../../urls';
import headers from '../../utils/headers';
import { activeButtonStyle } from './utils';
import ToolbarHistory from './ToolbarHistory';

const taskCompletePath = '/task/completed';
interface History {
  action: string;
  name: string;
  date: string;
}
const getSubmitButtonText = {
  new: 'Submit',
  renewal: 'Generate Checklist',
  draft: 'Generate',
  checklist: 'Submit',
};
const draftText = {
  new: ' Checklist',
  renewal: ' Result',
};
function saveDraftRequest(
  taskId: string,
  borrowerName: string,
  docType: string,
  topDate: string,
  caseType?: string
) {
  if (caseType === 'new') {
    return axios.post(
      DRAFT_ENDPOINT,
      {
        taskId,
        borrowerName,
        caseType: caseType?.toUpperCase(),
        userInputs: {
          docType,
          topDate,
        },
      },
      { headers }
    );
  }
  return axios.post(
    DRAFT_ENDPOINT,
    {
      taskId,
      borrowerName,
      caseType: caseType?.toUpperCase(),
      userInputs: {
        docType,
      },
    },
    { headers }
  );
}
function updateNameRequest(taskId: string, insuredName: string) {
  return axios.post(
    NAME_UPDATE_ENDPOINT,
    {
      taskId,
      insuredName,
    },
    { headers }
  );
}
interface Mutations {
  saveChecklist: any;
  saveDraft: any;
  saveInsuredName: any;
  saveGcin: any;
}
const handleSubmit = (
  { saveDraft, saveInsuredName, saveGcin }: Mutations,
  values: ValueType,
  insuredName: string,
  type: string,
  navigate: NavigateFunction
) => {
  if (type === 'checklist') {
    navigate('/');
  }
  if (type === 'draft') {
    saveDraft(values);
  }
  if (type === 'new') {
    if (insuredName !== values.insuredName) {
      saveInsuredName(values);
    } else {
      navigate('/');
    }
  }
  if (type === 'renewal') {
    if (insuredName === '') {
      saveInsuredName(values);
    }
    saveGcin(values);
  }
};
function ToolbarButtons({ type, insuredName }: { type: string; insuredName: string }) {
  const [urlParams] = useSearchParams();
  const taskId = urlParams.get('id') ?? '1';
  const navigate = useNavigate();
  const { mutate: discardTask } = useMutation({
    mutationFn: () =>
      axios.delete(`${DISCARD_ENDPOINT}/${taskId}`, { headers }).then(() => navigate('/')),
  });
  const { values, isValid, setFieldValue } = useFormikContext<ValueType>();
  const { mutate: saveDraft } = useMutation(
    ({ borrowerName, docType, topDate, caseType }: ValueType) =>
      saveDraftRequest(taskId, borrowerName, docType, topDate, caseType),
    { onSuccess: () => navigate('/') }
  );
  const { mutate: saveChecklist } = useMutation({
    mutationFn: ({ checklistItems: collaterals }: ValueType) =>
      axios.post(
        DRAFT_ENDPOINT,
        {
          taskId,
          collaterals,
        },
        { headers }
      ),
  });
  const { mutateAsync: saveInsuredName } = useMutation({
    mutationFn: ({ insuredName: i }: ValueType) => updateNameRequest(taskId, i),
    onSuccess: type === 'new' ? () => navigate('/') : undefined,
  });
  const { mutate: saveGcin } = useMutation({
    mutationFn: ({
      borrowerName,
      caseType,
      docType,
      gcin,
      gcinLabel,
      insuredName: selectedInsured,
    }: ValueType) =>
      axios.post(
        DRAFT_ENDPOINT,
        {
          taskId,
          borrowerName,
          caseType: caseType?.toUpperCase(),
          userInputs: {
            selectedInsured,
            docType,
            gcin,
            gcinLabel,
          },
        },
        { headers }
      ),
    onSuccess: () => navigate('/'),
  });
  const mutations = {
    saveChecklist,
    saveDraft,
    saveInsuredName,
    saveGcin,
  };
  return (
    <>
      <Grid item>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => discardTask()}
          sx={{
            bgcolor: '#EEEEEE',
            color: '#000000',
            ':disabled': { bgcolor: '#cccccc', color: '#FFF', boxShadow: 2 },
            p: '9px 16px',
            minWidth: '128px',
          }}
        >
          <Typography fontWeight={700}>Discard</Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => handleSubmit(mutations, values, insuredName, type, navigate)}
          sx={{
            bgcolor: '#EEEEEE',
            color: '#000000',
            ':disabled': { bgcolor: '#cccccc', color: '#FFF', boxShadow: 2 },
            p: '9px 16px',
            minWidth: '128px',
          }}
        >
          <Typography fontWeight={700}>Save as Draft</Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          disabled={!isValid}
          sx={activeButtonStyle(isValid)}
          form="maker-upload-form"
          formAction="submit"
          onClick={() => setFieldValue('openDialog', true)}
        >
          <Typography fontWeight={700}>
            {`${getSubmitButtonText[type as keyof typeof getSubmitButtonText]}${
              type === 'draft' ? draftText[values.caseType as keyof typeof draftText] : ''
            }`}
          </Typography>
        </Button>
      </Grid>
    </>
  );
}
export default function CreateTaskToolbar({
  history,
  type,
  insuredName,
}: {
  history: History;
  type: string;
  insuredName?: string;
}) {
  const location = useLocation();

  return (
    <Box sx={{ backgroundColor: '#FFF', mb: '16px' }}>
      <Toolbar disableGutters sx={{ paddingX: '32px' }}>
        <Button component={Link} startIcon={<ArrowBackIcon />} sx={{ color: '#000' }} to="/">
          <Typography fontWeight={600} fontSize="16px" lineHeight="16px" noWrap width="75px">
            Task List
          </Typography>
        </Button>

        <Grid container columnSpacing="16px">
          <Grid item sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <Stack direction="row">{history && <ToolbarHistory history={history} />}</Stack>
          </Grid>
          {!location.pathname.startsWith(taskCompletePath) && type !== 'processing' && (
            <ToolbarButtons type={type} insuredName={insuredName ?? ''} />
          )}
        </Grid>
      </Toolbar>
    </Box>
  );
}

CreateTaskToolbar.defaultProps = {
  insuredName: '',
};
