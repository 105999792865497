import { Close, Edit } from '@mui/icons-material';
import { Container, IconButton, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useFormikContext } from 'formik';
import TextBox from '../../../components/Form/Textbox';
import MakerDialog from '../Dialog';
import {
  ChecklistItemTable,
  CollapsibleRow,
  checklistCollumns,
  getAdminKeyword,
  getChecklistItems,
} from '../StaticListTable';
import { QueryResponse, StaticList } from '../interfaces';

interface FormikValues {
  data: {
    keywordId: number | null;
    checklistItem: string;
    documentName: string;
    lookupName: string;
    keyword: string;
    previousKeyword: string;
  }[];
  openDialog: boolean;
}

function Keyword({ info }: { info: CellContext<StaticList, string> }) {
  const {
    values: { data },
  } = useFormikContext<FormikValues>();
  const index = data.findIndex((d) => d?.keywordId === info.row.original.keywordId);
  return info.rowEditable && info.editable ? (
    <TextBox
      name={`data[${index}].keyword`}
      placeholder="Keyword"
      values={data?.[index]?.keyword}
      width="200px"
    />
  ) : (
    <>
      <Typography>{data?.[index]?.keyword ?? info.row.original.keyword}</Typography>
      {data?.[index]?.keyword && (
        <Typography color="#975704">Previously: {data?.[index]?.previousKeyword}</Typography>
      )}
    </>
  );
}
function EditButton({ info }: { info: CellContext<StaticList, any> }) {
  const formik = useFormikContext<FormikValues>();
  const handleAddField = () => {
    info.setRowEditable(true);
    if (!formik.values.data.find((data) => data.keywordId === info.row.original.keywordId)) {
      formik.setFieldValue('data', [
        ...formik.values.data,
        { ...info.row.original, previousKeyword: info.row.original.keyword },
      ]);
    }
  };

  const handleRemoveField = () => {
    formik.setFieldValue(
      'data',
      formik.values.data.filter((data) => data.keywordId !== info.row.original.keywordId)
    );
    info.setRowEditable(false);
  };
  if (info.rowEditable) {
    return (
      <IconButton onClick={() => handleRemoveField()}>
        <Close />
      </IconButton>
    );
  }
  return (
    <IconButton onClick={() => handleAddField()}>
      <Edit />
    </IconButton>
  );
}
const columnHelper = createColumnHelper<StaticList>();

const columns = [
  ...checklistCollumns.slice(0, -1),
  columnHelper.accessor('keyword', {
    cell: (info) => <Keyword info={info} />,
    header: 'Keyword',
  }),
  columnHelper.display({
    id: 'edit',
    cell: (info) => info.editable && <EditButton info={info} />,
  }),
];

function Row({ checklistItem, editable }: { checklistItem: string; editable: boolean }) {
  const { data } = useQuery<QueryResponse>(
    ['Static List', checklistItem],
    () => getAdminKeyword(checklistItem),
    { keepPreviousData: true }
  );
  const list = data ? data[checklistItem as keyof QueryResponse] : [];
  return (
    <CollapsibleRow
      checklistItem={checklistItem}
      columns={columns}
      data={list}
      editable={editable}
    />
  );
}
interface IChecklistItems {
  checkListItem: string;
  locked: boolean;
  metadata: {
    [index: string]: string[];
  };
}
export default function EditPage() {
  const { data, isLoading } = useQuery<IChecklistItems[]>(['ChecklistItems'], () =>
    getChecklistItems()
  );
  const { values } = useFormikContext<FormikValues>();

  const checklistItems = data
    ?.filter(
      (item) =>
        item.locked === false ||
        values.data.map((d) => d.checklistItem).includes(item.checkListItem)
    )
    .map((item) => item.checkListItem);
  return (
    <Container sx={{ bgcolor: '#FFF', minWidth: 'calc(100vw - 64px)', paddingBottom: '32px' }}>
      <Stack direction="row" justifyContent="flex-start" paddingY="16px">
        <Typography variant="h1" marginRight="8px">
          Edit Keywords
        </Typography>
      </Stack>
      {!isLoading && (
        <ChecklistItemTable checklistItems={checklistItems ?? []} columns={columns} edit>
          {(checklistItem, editable) => (
            <Row key={checklistItem} checklistItem={checklistItem} editable={editable ?? false} />
          )}
        </ChecklistItemTable>
      )}
      <MakerDialog taskType="EDIT" />
    </Container>
  );
}
