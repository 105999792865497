export function historyDate(date: string) {
  return ` ${Intl.DateTimeFormat('en-gb', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }).format(new Date(date))} at ${Intl.DateTimeFormat('en-gb', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(new Date(date))}`;
}

export function activeButtonStyle(active: boolean) {
  return {
    bgcolor: !active ? '#cccccc' : '#0087D7',
    ':hover': {
      bgcolor: !active ? '#bebebe' : '#0097E7',
    },
    ':disabled': { bgcolor: '#cccccc', color: '#FFF', boxShadow: 2 },
    p: '9px 16px',
    minWidth: '128px',
  };
}
