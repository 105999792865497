import { Container, Divider, Link, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { Link as RouterLink } from 'react-router-dom';
import TaskListTable from '../../components/TaskList';
import { AdminToolbar } from '../../components/toolbars';
import { ADMIN_TASK_LIST } from '../../urls';
import {
  ChecklistItemTable,
  CollapsibleRow,
  checklistCollumns as columns,
  getAdminKeyword,
  getChecklistItems,
} from './StaticListTable';
import { QueryResponse } from './interfaces';

interface Task {
  taskId: number;
  borrowerName: string;
  submittedBy: string;
  approvedBy: string;
  statusMsg: string;
  uploadedTime: string;
  statusText: string;
  statusSubText: string;
}
const taskColumnHelper = createColumnHelper<Task>();

const link = {
  UNDER_REVIEW: 'review',
  APPROVED: 'completed',
  REJECTED: 'completed',
};
function TaskLink({ info }: { info: CellContext<any, any> }) {
  const id = info.row.original.taskId;
  return (
    <Link
      variant="task_link"
      to={`${link[info.row.original.statusMsg as keyof typeof link] ?? 'maker'}?id=${id}${
        info.row.original.statusMsg === 'DRAFT' ? '&draft' : ''
      }`}
      underline="hover"
      component={RouterLink}
    >
      Task ID {info.getValue()}
    </Link>
  );
}
function collumnCell(info: CellContext<any, any>) {
  return (
    <Typography variant="table_value">{info.getValue() == null ? '-' : info.getValue()}</Typography>
  );
}
const taskColumn = [
  taskColumnHelper.accessor('taskId', {
    cell: (info) => (
      <>
        <TaskLink info={info} />
        <Typography variant="table_value">
          {info.row.original.borrowerName ? info.row.original.borrowerName : '-'}
        </Typography>
      </>
    ),
    header: 'Task',
  }),
  taskColumnHelper.accessor('submittedBy', {
    cell: (info) => collumnCell(info),
    header: 'Submitted By',
  }),
  taskColumnHelper.accessor('approvedBy', {
    cell: (info) => collumnCell(info),
    header: 'Approved By',
  }),
  taskColumnHelper.accessor('statusMsg', {
    cell: (info) => (
      <>
        <Typography variant="table_value">{info.row.original.statusText ?? '-'}</Typography>
        <Typography variant="table_value" sx={{ color: '#999999' }}>
          {info.row.original.statusSubText ?? '-'}
        </Typography>
      </>
    ),
    header: 'Status',
  }),
];

function Row({ checklistItem }: { checklistItem: string }) {
  const { data } = useQuery<QueryResponse>(
    ['Static List', checklistItem],
    () => getAdminKeyword(checklistItem),
    { keepPreviousData: true }
  );

  const list = data ? data[checklistItem as keyof QueryResponse] : [];
  return <CollapsibleRow checklistItem={checklistItem} columns={columns} data={list} />;
}

interface IChecklistItems {
  checkListItem: string;
  locked: boolean;
  metadata: {
    [index: string]: string[];
  };
  description: string;
}
export default function Home() {
  const { data } = useQuery<IChecklistItems[]>(['checklistItems'], () => getChecklistItems(), {
    keepPreviousData: true,
  });

  return (
    <>
      <AdminToolbar />
      <Container sx={{ bgcolor: '#FFF', minWidth: 'calc(100vw - 64px)' }}>
        <Typography id="caseType" variant="h1" margin="16px 8px 16px 0px" paddingTop="16px">
          Task List
        </Typography>
        <Divider />
        <TaskListTable fetchUrl={ADMIN_TASK_LIST} admin columns={taskColumn} />
      </Container>
      <Container sx={{ bgcolor: '#FFF', minWidth: 'calc(100vw - 64px)', marginBottom: '16px' }}>
        <Typography id="caseType" variant="h1" margin="16px 8px 16px 0px" paddingTop="16px">
          Static List
        </Typography>
        <Divider />
        {data ? (
          <ChecklistItemTable
            key="checklistItemSuccess"
            columns={columns}
            checklistItems={data?.map((item) => item.checkListItem) ?? []}
          >
            {(checklistItem) => <Row key={checklistItem} checklistItem={checklistItem} />}
          </ChecklistItemTable>
        ) : (
          <ChecklistItemTable key="checklistItemsLoading" columns={columns} checklistItems={[]}>
            {(checklistItem) => <Row key={checklistItem} checklistItem={checklistItem} />}
          </ChecklistItemTable>
        )}
      </Container>
    </>
  );
}
