import { createSlice } from '@reduxjs/toolkit';

export interface ICheckBox {
  [key: string]: boolean;
}

interface TaskStore {
  caseType: string;
  username: string;
  collateralCheckBoxes: ICheckBox | null;
  authLevel: number;
}

const initialState: TaskStore = {
  caseType: sessionStorage.getItem('caseType') ?? 'New Cases',
  collateralCheckBoxes: null,
  authLevel: 0,
  username: '',
};

export const taskStore = createSlice({
  name: 'isValid',
  initialState,
  reducers: {
    setCaseType: (state, action) => {
      state.caseType = action.payload;
      sessionStorage.setItem('caseType', action.payload);
    },
    setCollateralCheckBoxes: (state, action) => {
      state.collateralCheckBoxes = action.payload;
    },

    updateCollateralCheckBoxes: (state, action) => {
      state.collateralCheckBoxes = {
        ...state.collateralCheckBoxes,
        [action.payload.name as string]: action.payload.checked,
      };
    },
    setAuthLevel: (state, action) => {
      state.authLevel = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    selectAllCollateralCheckBoxes: (state, action) => {
      Object.keys(state.collateralCheckBoxes as ICheckBox).forEach((key) => {
        if (state.collateralCheckBoxes !== null) {
          state.collateralCheckBoxes[key] = action.payload;
        }
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCaseType,
  setCollateralCheckBoxes,
  setAuthLevel,
  updateCollateralCheckBoxes,
  setUsername,
  selectAllCollateralCheckBoxes,
} = taskStore.actions;

export default taskStore.reducer;
