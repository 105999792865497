import { Box, Typography } from '@mui/material';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormikContext } from 'formik';
import headers from '../../utils/headers';
import { ADMIN_FILE_ENDPOINT } from '../../urls';

interface DropzoneProps {
  files: string[] | string;
  filesStatus: string[] | string;
  padding?: string;
  maxWidth?: string;
  height?: string;
  uploadUrl: string;
  checkListItem?: number;
  assetId?: string;
  taskId: string;
}
interface MutationQueryArgs {
  file: File;
  index: number;
  status: string[] | string;
  checkListItem?: number;
  assetId?: string;
}
const requestBody = (taskId: string, assetId?: string, checkListItem?: number, file?: File) => {
  if (assetId !== null) {
    return { taskId, assetId, checkListItem, file };
  }
  return { taskId, checkListItem, file };
};

function UploadFileRequest(
  file: File,
  uploadUrl: string,
  taskId: string,
  filesStatus: string[] | string,
  checkListItem?: number,
  assetId?: string
) {
  return axios
    .put(
      `${uploadUrl}`,
      !Array.isArray(filesStatus)
        ? requestBody(taskId, assetId, checkListItem, file)
        : {
            taskId,
            file,
          },
      {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((res) => res.data);
}
type MyMutationFn<T> = UseMutateFunction<any, unknown, T>;

type IFileDrop = (
  acceptedFiles: Array<File>,
  files: string[] | string,
  filesStatus: string[] | string,
  fileUpload: MyMutationFn<MutationQueryArgs>,
  setFieldValue: (name: string, value: any, validate?: boolean) => void
) => { filesUploaded: string[] | string; filesStatus: string[] | string };

const handleFileDrop: IFileDrop = (
  acceptedFiles,
  files,
  filesStatus,
  fileUpload,
  setFieldValue
) => {
  const filesUploaded = Array.isArray(files)
    ? files.concat(...acceptedFiles.map((file) => file.name))
    : acceptedFiles[0].name;
  setFieldValue('filesUploaded', filesUploaded);
  const status = Array.isArray(filesStatus)
    ? filesStatus.concat(...acceptedFiles.map(() => 'pending'))
    : 'pending';
  setFieldValue('filesStatus', status);
  acceptedFiles.forEach((file, index) => {
    fileUpload({
      file,
      index: Array.isArray(files) ? files.length + index : 0,
      status,
    });
  });
  return { filesUploaded, filesStatus: status };
};
export default function Dropzone({
  filesStatus,
  files,
  padding,
  maxWidth,
  height,
  uploadUrl,
  checkListItem,
  taskId,
  assetId,
}: // assetId,
DropzoneProps) {
  const { setFieldValue } = useFormikContext();
  const fileUpload = useMutation({
    mutationFn: ({ file }: MutationQueryArgs) =>
      UploadFileRequest(file, uploadUrl, taskId, filesStatus, checkListItem, assetId),
    onSuccess: (data, { index, status }) => {
      if (Array.isArray(status)) {
        const newstatus = status;
        newstatus[index] = 'success';
        setFieldValue('filesStatus', newstatus);
      }
      if (typeof status === 'string') {
        setFieldValue('filesStatus', 'success');
      }
    },
    onError: (error, { index, status }) => {
      if (Array.isArray(status)) {
        const newstatus = status;
        newstatus[index] = 'error';
        setFieldValue('filesStatus', newstatus);
      }
      if (typeof status === 'string') {
        setFieldValue('filesStatus', 'error');
      }
    },
  });

  const onDrop = React.useCallback(
    (acceptedFiles: Array<File>) =>
      handleFileDrop(acceptedFiles, files, filesStatus, fileUpload.mutate, setFieldValue),
    [setFieldValue, files, fileUpload, filesStatus]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      uploadUrl === ADMIN_FILE_ENDPOINT
        ? { 'text/csv': ['.csv'] }
        : { 'application/pdf': ['.pdf'] },
  });
  return (
    <Box {...getRootProps()} sx={{ padding, bgcolor: '#EEEEEE', maxWidth }}>
      <Box
        sx={{
          border: 2,
          borderStyle: 'dashed',
          borderColor: '#CCCCCC',
          height: `calc(${height} - 16px)`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <input
          data-testid="dropzone"
          name="fileUploaded"
          id="file"
          type="file"
          {...getInputProps()}
        />
        {isDragActive ? (
          <Typography>Drop the files here ...</Typography>
        ) : (
          <Typography sx={{ '& > strong': { textDecorationLine: 'underline' } }}>
            <strong>Select files</strong> OR drag them here.
          </Typography>
        )}
      </Box>
    </Box>
  );
}

Dropzone.defaultProps = {
  padding: '8px 10px',
  maxWidth: '940px',
  height: '94px',
  checkListItem: null,
  assetId: null,
};

export { handleFileDrop };
