import { Container, Stack, Typography } from '@mui/material';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useFormikContext } from 'formik';
import CheckerDialog from '../Dialog';
import { ChecklistItemTable, CollapsibleRow, checklistCollumns } from '../StaticListTable';
import { StaticList } from '../interfaces';

interface FormikValues {
  data: {
    keywordId: number | null;
    checklistItem: string;
    documentName: string;
    lookupName: string;
    keyword: string;
    previousKeyword: string;
  }[];
  openApprove: boolean;
  openReject: boolean;
  openReturn: boolean;
}
const columnHelper = createColumnHelper<StaticList>();
function Keyword({ info }: { info: CellContext<StaticList, string> }) {
  const {
    values: { data },
  } = useFormikContext<FormikValues>();
  const index = data.findIndex((d) => d?.keywordId === info.row.original.keywordId);
  return (
    <>
      <Typography>{data?.[index]?.keyword}</Typography>
      <Typography color="#975704">Previously: {data?.[index]?.previousKeyword}</Typography>
    </>
  );
}
const columns = [
  ...checklistCollumns.slice(0, -1),
  columnHelper.accessor('keyword', {
    cell: (info) => <Keyword info={info} />,
    header: 'Keyword',
  }),
  columnHelper.display({
    id: 'edit',
  }),
];

function Row({ checklistItem, data }: { checklistItem: string; data: any[] }) {
  return <CollapsibleRow checklistItem={checklistItem} columns={columns} data={data} open />;
}

export default function Add({ data }: { data: any[] }) {
  const checklistItems = data
    ?.map((d: any) => d.checklistItem)
    .filter((v: any, i: any, a: any) => a.indexOf(v) === i);
  return (
    <Container sx={{ bgcolor: '#FFF', minWidth: 'calc(100vw - 64px)', paddingBottom: '32px' }}>
      <Stack direction="row" justifyContent="flex-start" paddingY="16px">
        <Typography variant="h1" marginRight="8px">
          Edit Keywords
        </Typography>
      </Stack>
      <ChecklistItemTable checklistItems={checklistItems ?? []} columns={columns}>
        {(checklistItem) => <Row key={checklistItem} checklistItem={checklistItem} data={data} />}
      </ChecklistItemTable>
      <CheckerDialog taskType="EDIT" />
    </Container>
  );
}
