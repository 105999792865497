import { FormControl, Select } from '@mui/material';

interface ISelectInput {
  name: string;
  children: React.ReactNode;
  width: string;
  renderValue: (value: any) => React.ReactNode;
  handleChange: any;
  value: any;
}
export default function SelectInput({
  name,
  children,
  width,
  renderValue,
  handleChange,
  value,
}: ISelectInput) {
  return (
    <FormControl
      fullWidth
      sx={{
        width,
      }}
    >
      <Select
        name={name}
        id={name}
        value={value}
        displayEmpty
        renderValue={renderValue}
        onChange={handleChange}
        sx={{
          color: value ? '000000' : '#999999',
        }}
        SelectDisplayProps={{
          style: {
            padding: '10px 16px',
          },
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
}
