import { ExpandMore } from '@mui/icons-material';
import { Button, Container, Divider, Link, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { Link as RouterLink } from 'react-router-dom';
import TaskListTable from '../../components/TaskList';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setCaseType, setCollateralCheckBoxes } from '../../redux/taskStore';
import { LIST_ENDPOINT } from '../../urls';

interface Task {
  taskId: number;
  borrowerName: string;
  submittedBy: string;
  approvedBy: string;
  statusMsg: string;
  uploadedTime: string;
  statusText: string;
  statusSubText: string;
}
const columnHelper = createColumnHelper<Task>();
function TaskLink({ info }: { info: CellContext<any, any> }) {
  const caseType = useAppSelector(({ taskStore }) => taskStore.caseType)
    .toLowerCase()
    .split(' ')[0];
  const link = (status: string) => {
    if (status === 'DRAFT') {
      return '/task/create';
    }
    if (status === 'COMPLETED' || status === 'FAILED') {
      return `/task/completed/${caseType}`;
    }
    if (status === 'PROCESSING') {
      return `/task/processing/${caseType}`;
    }
    return `/task/view/${caseType}`;
  };
  return (
    <Link
      variant="task_link"
      to={`${link(info.row.original.statusMsg)}?id=${info.getValue()}`}
      underline="hover"
      component={RouterLink}
    >
      Task ID {info.getValue()}
    </Link>
  );
}
function collumnCell(info: CellContext<any, any>) {
  return (
    <Typography variant="table_value">{info.getValue() == null ? '-' : info.getValue()}</Typography>
  );
}
const columns = [
  columnHelper.accessor('taskId', {
    cell: (info) => (
      <>
        <TaskLink info={info} />
        <Typography variant="table_value">
          {info.row.original.borrowerName ? info.row.original.borrowerName : '-'}
        </Typography>
      </>
    ),
    header: 'Task',
  }),
  columnHelper.accessor('submittedBy', {
    cell: (info) => collumnCell(info),
    header: 'Submitted By',
  }),
  columnHelper.accessor('approvedBy', {
    cell: (info) => collumnCell(info),
    header: 'Approved By',
  }),
  columnHelper.accessor('statusMsg', {
    cell: (info) => (
      <>
        <Typography variant="table_value">{info.row.original.statusText ?? '-'}</Typography>
        <Typography variant="table_value" sx={{ color: '#999999' }}>
          {info.row.original.statusSubText ?? '-'}
        </Typography>
      </>
    ),
    header: 'Status',
  }),
];
export default function Home() {
  const caseType = useAppSelector((state) => state.taskStore.caseType);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.innerText !== '') {
      dispatch(setCaseType(event.currentTarget.innerText));
    }
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  React.useEffect(() => {
    dispatch(setCollateralCheckBoxes(null));
  }, [dispatch]);

  return (
    <Container sx={{ bgcolor: '#FFF', minWidth: 'calc(100vw - 64px)' }}>
      <Button
        color="inherit"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ marginY: '16px', height: '32px' }}
      >
        {/* Margin is 8px because svg wrapper already has a 6px padding  */}
        <Typography id="caseType" variant="h1" marginRight="8px">
          {caseType}
        </Typography>
        <ExpandMore htmlColor="#9BA4AB" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        elevation={1}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>New Cases</MenuItem>
        <MenuItem onClick={handleClose}>Renewal Cases</MenuItem>
      </Menu>
      <Divider />
      <TaskListTable fetchUrl={LIST_ENDPOINT} columns={columns} />
    </Container>
  );
}
