import '@fontsource/open-sans';
import { createTheme, ThemeOptions } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/Typography/' {
  interface TypographyPropsVariantOverrides {
    toolbar_h1: true;
    task_link: true;
    table_header: true;
    table_value: true;
    table_button: true;
    form_label: true;
    checklist_header_h1: true;
    checklist_header_h2: true;
    checklist_header_key: true;
    checklist_header_value: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  toolbar_h1: React.CSSProperties;
  task_link: React.CSSProperties;
  table_header: React.CSSProperties;
  table_value: React.CSSProperties;
  table_button: React.CSSProperties;
  form_label: React.CSSProperties;
  checklist_header_h1: React.CSSProperties;
  checklist_header_h2: React.CSSProperties;
  checklist_header_key: React.CSSProperties;
  checklist_header_value: React.CSSProperties;
}

const theme = createTheme({
  shape: {
    borderRadius: 2,
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    toolbar_h1: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '16px',
    },
    h1: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    task_link: {
      fontWeight: '600',
      lineHeight: '20px',
      display: 'block',
    },
    table_header: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 700,
    },
    table_button: {
      fontWeight: 600,
      lineHeight: '16px',
      fontSize: '14px',
      letterSpacing: '-0.005em',
    },
    table_value: {
      lineHeight: '20px',
      display: 'block',
    },
    body1: {
      fontSize: '14px',
    },
    button: {
      textTransform: 'none',
      fontSize: '14px',
    },
    form_label: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
    },
    checklist_header_h1: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '32px',
    },
    checklist_header_h2: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '20px',
    },
    checklist_header_key: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
    },
    checklist_header_value: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  } as ExtendedTypographyOptions,
  palette: {
    primary: {
      main: '#0087D7',
    },
    secondary: {
      main: '#59656D',
    },
    background: {
      default: '#e5e5e5',
    },
    text: {
      primary: '#222222',
      secondary: '#FFF',
    },
  },
} as ThemeOptions);

export default theme;
