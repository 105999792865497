import { Typography } from '@mui/material';
import { historyDate } from './utils';

interface History {
  action: string;
  name: string;
  date: string;
}

export default function ToolbarHistory({ history }: { history: History }) {
  return (
    <Typography fontSize="12px" lineHeight="16px" color="#777777" sx={{ marginLeft: 'auto' }}>
      Last {history.action.charAt(0).toUpperCase() + history.action.slice(1).toLowerCase()} by{' '}
      {history.name} on {historyDate(history.date)}
    </Typography>
  );
}
