import { Container, Stack, Typography } from '@mui/material';
import {
  ChecklistItemTable,
  CollapsibleRow,
  checklistCollumns as columns,
} from '../StaticListTable';
import CheckerDialog from '../Dialog';

// Form Component
function Row({ checklistItem, data }: { checklistItem: string; data: any[] }) {
  return <CollapsibleRow checklistItem={checklistItem} data={data} columns={columns} open />;
}
export default function Add({ data }: { data: any[] }) {
  const checklistItems = data?.map((d) => d.checklistItem).filter((v, i, a) => a.indexOf(v) === i);

  return (
    <Container sx={{ bgcolor: '#FFF', minWidth: 'calc(100vw - 64px)', paddingBottom: '32px' }}>
      <Stack direction="row" justifyContent="flex-start" paddingY="16px">
        <Typography variant="h1" marginRight="8px">
          Remove Keywords
        </Typography>
      </Stack>
      <ChecklistItemTable checklistItems={checklistItems ?? []} columns={columns}>
        {(checklistItem) => <Row key={checklistItem} checklistItem={checklistItem} data={data} />}
      </ChecklistItemTable>
      <CheckerDialog taskType="DELETE" />
    </Container>
  );
}
