import { Search } from '@mui/icons-material';
import {
  Autocomplete,
  Checkbox,
  TextField,
  Typography,
  autocompleteClasses,
  capitalize,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useFormikContext } from 'formik';
import pluralize from 'pluralize';
import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Form, FormRow } from '../../../components/Form';
import { FileAttachment } from '../../../components/Form/Attachment';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ICheckBox, selectAllCollateralCheckBoxes } from '../../../redux/taskStore';
import { SEARCH_CLS_ENDPOINT, TASK_FILE_ENDPOINT } from '../../../urls';
import headers from '../../../utils/headers';
import { ValueType } from '../interface';

const INPUT_WIDTH = '348px';

function getAllBoolean(obj: ICheckBox) {
  const { length } = Object.values(obj).filter((o) => o === true);
  return Boolean(length === Object.keys(obj).length);
}
const errorFieldStyle = {
  '& fieldset': {
    borderColor: '#cc0000',
  },

  '&:hover fieldset': {
    borderColor: '#cc0000',
  },
  '&.Mui-focused fieldset': {
    borderColor: '#cc0000',
  },
};
const textFieldStyle = (error?: boolean) => ({
  '& .MuiOutlinedInput-root': error ? { ...errorFieldStyle } : {},
  '& .MuiOutlinedInput-input': { padding: '10px 16px' },
});
const taskCompletePath = '/task/completed';
interface ClsQueryResponse {
  value: string;
  label: string;
}

interface ICLSAutoComplete {
  setFieldValue: any;
  insuredName: string;
  value: ValueType;
}
function CLSAutoComplete({ setFieldValue, insuredName, value }: ICLSAutoComplete) {
  const [searchCls, setSearchCls] = React.useState<string>('');
  const [urlParams] = useSearchParams();
  const taskId = urlParams.get('id') as string;
  const { data: clsData } = useQuery<ClsQueryResponse[]>({
    queryKey: ['getCls', taskId, insuredName, searchCls, value.gcinLabel],
    queryFn: () => {
      if (searchCls) {
        return axios
          .get(SEARCH_CLS_ENDPOINT, {
            params: { taskId, name: searchCls, maxResults: 10 },
            headers,
          })
          .then((res) => res.data.topSearchResult);
      }
      return axios
        .get(SEARCH_CLS_ENDPOINT, {
          params: { taskId, name: insuredName, maxResults: 10 },
          headers,
        })
        .then((res) => res.data.topSearchResult);
    },
  });
  return (
    <Autocomplete
      disablePortal
      freeSolo
      autoSelect
      onChange={(e, clsValue) => {
        if (typeof clsValue !== 'string') {
          setFieldValue('gcin', clsValue?.value);
          setFieldValue('gcinLabel', clsValue?.label);
        } else {
          setFieldValue('gcinLabel', clsValue);
        }
      }}
      id="collateralGcinLabel"
      options={clsData ?? []}
      defaultValue={
        value
          ? { value: value.gcin, label: value.gcinLabel }
          : { value: 'CLS Endpoint not found', label: '' }
      }
      filterOptions={(options) => options}
      getOptionLabel={(option) => {
        if (typeof option !== 'string') {
          return option.label;
        }
        return option;
      }}
      popupIcon={<Search />}
      sx={{
        width: INPUT_WIDTH,
        marginY: '8px',
        justifySelf: 'stretch',
        [`& .${autocompleteClasses.popupIndicator}`]: {
          transform: 'none',
        },
        '& .MuiInputBase-root ': {
          padding: '0px',
          '& .MuiAutocomplete-input': {
            padding: '10px 16px',
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          data-testid="collateralGcin"
          InputLabelProps={{
            sx: { color: '#999999' },
          }}
          onChange={(e) => setSearchCls(e.target.value)}
          placeholder="Select CLS Collateral Owner GCIN / Name "
        />
      )}
    />
  );
}

function InsuredNameAutocomplete({ insuredName, type }: { insuredName: string; type: string }) {
  const { values, setFieldValue, errors } = useFormikContext<ValueType>();

  const options = type === 'new' ? [] : insuredName?.split(',') ?? [];
  const input = options.length > 1 || insuredName === '';

  return input ? (
    <Autocomplete
      disablePortal
      freeSolo
      autoSelect
      onChange={(e, value) => {
        setFieldValue('insuredName', value);
      }}
      id="insuredName"
      value={values.selectedInsured ? values.insuredName : ''}
      options={insuredName === '' ? [] : options}
      popupIcon={<Search />}
      sx={{
        width: INPUT_WIDTH,
        marginY: '8px',
        justifySelf: 'stretch',
        [`& .${autocompleteClasses.popupIndicator}`]: {
          transform: 'none',
        },
        '& .MuiInputBase-root ': {
          padding: '0px',
          '& .MuiAutocomplete-input': {
            padding: '10px 16px',
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          data-testid="insuredName"
          {...params}
          sx={textFieldStyle(Boolean(errors.insuredName && values.selectedInsured === undefined))}
          placeholder="Select Insured Name"
        />
      )}
    />
  ) : (
    <Typography marginBottom="8px" display="block">
      {values.insuredName}
    </Typography>
  );
}
export default function TaskInfo({ type, insuredName }: { type: string; insuredName: string }) {
  const dispatch = useAppDispatch();
  const collaterals = useAppSelector(
    ({ taskStore: { collateralCheckBoxes } }) => collateralCheckBoxes as ICheckBox
  );
  const isAllCollateralChecked = collaterals ? getAllBoolean(collaterals) : null;
  const { values, setFieldValue, errors } = useFormikContext<ValueType>();
  const [urlParams] = useSearchParams();
  const taskId = urlParams.get('id') as string;
  const location = useLocation();
  return (
    <Form formId="form">
      <FormRow label="Borrower Name">
        <Typography>{values.borrowerName}</Typography>
      </FormRow>
      <FormRow label="Type of Case">
        <Typography>{capitalize(values.caseType)} Cases</Typography>
      </FormRow>
      <FormRow label="Type of Document">
        <Typography>{capitalize(values.docType)}</Typography>
      </FormRow>
      {values.caseType === 'new' && (
        <FormRow label="TOP/ Loan Disbursement Date">
          {new Date(values.topDate).toLocaleDateString('en-GB', { dateStyle: 'long' })}
        </FormRow>
      )}
      <FormRow label="Upload Files">
        <Typography marginBottom="8px" fontWeight={700}>
          {values.filesUploaded.length === 0
            ? 'No file'
            : pluralize('file', values.filesUploaded.length, true)}{' '}
          attached
        </Typography>
        {values.filesUploaded.map((file, index) => (
          <FileAttachment
            filename={file}
            status={values.filesStatus[index]}
            width={INPUT_WIDTH}
            getEndpoint={`${TASK_FILE_ENDPOINT}?taskId=${taskId}&filename=${encodeURIComponent(
              file
            )}`}
            key={file}
          />
        ))}
      </FormRow>
      {type !== 'checklist' ? (
        <FormRow label="Insured Name">
          <InsuredNameAutocomplete insuredName={insuredName} type={type} />
          {errors.insuredName && !values.selectedInsured && (
            <Typography color="#cc0000">{errors.insuredName}</Typography>
          )}
          {type === 'renewal' && values.insuredName && (
            <CLSAutoComplete
              setFieldValue={setFieldValue}
              insuredName={values.insuredName}
              value={values}
            />
          )}
        </FormRow>
      ) : (
        <>
          <FormRow label="Insured Name">
            <Typography marginBottom="8px">{values.insuredName}</Typography>
            <Typography key={values.gcin} marginBottom="8px">
              {values.gcinLabel}
            </Typography>
          </FormRow>
          {!location.pathname.startsWith(taskCompletePath) && isAllCollateralChecked !== null && (
            <FormRow label="">
              <>
                <Checkbox
                  checked={isAllCollateralChecked}
                  data-testid="selectAll"
                  sx={{ m: 0, p: 0, color: '#999999' }}
                  onClick={() => {
                    dispatch(selectAllCollateralCheckBoxes(!isAllCollateralChecked));
                  }}
                />
                <Typography variant="form_label">Select All</Typography>
              </>
            </FormRow>
          )}
        </>
      )}
    </Form>
  );
}
