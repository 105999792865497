import { Divider, Grid, Typography } from '@mui/material';

interface FormRowProps {
  children: React.ReactNode;
  label?: string;
  topBorder?: boolean;
}

export default function FormRow({ children, label, topBorder }: FormRowProps) {
  return (
    <>
      <Grid item xs={2}>
        <Divider sx={topBorder ? { bgcolor: '#BBBBBB' } : undefined} />
      </Grid>
      {label && (
        <Grid item width="281px">
          <Typography variant="form_label">{label}</Typography>
        </Grid>
      )}
      <Grid item xs>
        {children}
      </Grid>
    </>
  );
}
FormRow.defaultProps = {
  label: '',
  topBorder: false,
};
