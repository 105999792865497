import { Checkbox, Container, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { useFormikContext } from 'formik';
import { Form } from '../../../components/Form';
import MakerDialog from '../Dialog';
import {
  ChecklistItemTable,
  CollapsibleRow,
  checklistCollumns,
  getAdminKeyword,
  getChecklistItems,
} from '../StaticListTable';
import { QueryResponse, StaticList } from '../interfaces';

interface AddFormValue {
  removedItems: string[];
  openDialog: boolean;
}
function CustomCheckbox({ value }: { value: any }) {
  const { handleChange } = useFormikContext();
  const { values } = useFormikContext<AddFormValue>();
  const idArray = values.removedItems.map((items) => JSON.parse(items).keywordId);
  return (
    <Checkbox
      checked={idArray.includes(value.keywordId)}
      name="removedItems"
      onChange={handleChange}
      sx={{
        color: '#999999',
      }}
      value={JSON.stringify(value)}
    />
  );
}
const columnHelper = createColumnHelper<StaticList>();
const columns = [
  columnHelper.accessor('checklistItem', {
    cell: ({
      row: {
        original: { keywordId, checklistItem, documentName, lookupName, keyword },
      },
    }) => (
      <CustomCheckbox value={{ keywordId, checklistItem, documentName, lookupName, keyword }} />
    ),
    header: 'Checklist Item',
  }),
  ...checklistCollumns.slice(1),
];
function Row({ checklistItem }: { checklistItem: string }) {
  const { data } = useQuery<QueryResponse>(
    ['Static List', checklistItem],
    () => getAdminKeyword(checklistItem),
    { keepPreviousData: true }
  );
  const list = data ? data[checklistItem as keyof QueryResponse] : [];
  return <CollapsibleRow checklistItem={checklistItem} columns={columns} data={list} />;
}
interface IChecklistItems {
  checkListItem: string;
  locked: boolean;
  metadata: {
    [index: string]: string[];
  };
}
export default function Remove() {
  const { data, isLoading } = useQuery<IChecklistItems[]>(['ChecklistItems'], () =>
    getChecklistItems()
  );

  const { values } = useFormikContext<AddFormValue>();

  const checklistItems = data
    ?.filter(
      (item) =>
        item.locked === false ||
        values.removedItems
          .map((d) => JSON.parse(d))
          .map((d) => d.checklistItem)
          .includes(item.checkListItem)
    )
    .map((item) => item.checkListItem);
  return (
    <Container sx={{ bgcolor: '#FFF', minWidth: 'calc(100vw - 64px)', paddingBottom: '32px' }}>
      <Stack direction="row" justifyContent="flex-start" paddingY="16px">
        <Typography variant="h1" marginRight="8px">
          Remove Keywords
        </Typography>
      </Stack>
      {!isLoading && (
        <ChecklistItemTable checklistItems={checklistItems ?? []} columns={columns}>
          {(checklistItem) => <Row key={checklistItem} checklistItem={checklistItem} />}
        </ChecklistItemTable>
      )}
      <MakerDialog taskType="DELETE" />
      <Form formId="remove" />
    </Container>
  );
}
