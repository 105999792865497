import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';

interface ITextBox {
  placeholder: string;
  name: string;
  width: string;
  values: string;
}
export default function TextBox({ name, placeholder, width, values }: ITextBox) {
  const { handleChange } = useFormikContext();
  return (
    <TextField
      name={name}
      placeholder={placeholder}
      defaultValue={values}
      onBlur={handleChange}
      inputProps={{
        sx: {
          height: '40px',
          py: 0,
          width: `calc(${width} - 29px)`,
        },
      }}
    />
  );
}
