import { Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { Form as FormikForm, useFormikContext } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { FileAttachment } from '../../../components/Form/Attachment';
import { ADMIN_FILE_ENDPOINT } from '../../../urls';
import CheckerDialog from '../Dialog';
import { getChecklistItems } from '../StaticListTable';
// Constants and Types
const INPUT_WIDTH = '348px';
// Sub Components

// Form Component

interface AddFormValue {
  checklistItem: string;
  lookupName: string;
  filesStatus: string;
  filesUploaded: string;
  documentName: string;
  openDialog: boolean;
  openApprove: boolean;
  openReject: boolean;
  openReturn: boolean;
}
interface IChecklistItems {
  checkListItem: string;
  locked: boolean;
  metadata: {
    [index: string]: string[];
  };
  description: string;
}
export default function Refresh() {
  const { values, handleSubmit } = useFormikContext<AddFormValue>();
  const { data } = useQuery<IChecklistItems[]>(['ChecklistItems'], () => getChecklistItems(), {
    initialData: [],
  });
  const [urlParams] = useSearchParams();
  const taskId = urlParams.get('id') ?? '';
  return (
    <Container sx={{ bgcolor: '#FFF', minWidth: 'calc(100vw - 64px)', paddingBottom: '32px' }}>
      <Stack direction="row" justifyContent="flex-start" paddingY="16px">
        <Typography variant="h1" marginRight="8px">
          Refresh Keywords
        </Typography>
      </Stack>
      <FormikForm id="maker-upload-form" onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
          columns={2}
          key={`${JSON.stringify(values)}`}
          sx={{ marginBottom: '32px' }}
        >
          <Grid item xs={2}>
            <Divider />
          </Grid>
          <Grid item width="281px">
            <Typography variant="form_label">Checklist Item</Typography>
          </Grid>
          <Grid item xs>
            <Typography>
              {data.filter((item) => item.checkListItem === values.checklistItem)?.[0]?.description}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Divider />
          </Grid>
          <Grid item width="281px">
            <Typography variant="form_label">Document name</Typography>
          </Grid>
          <Grid item xs>
            <Typography>{values.documentName}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Divider />
          </Grid>
          <Grid item width="281px">
            <Typography variant="form_label">Lookup name</Typography>
          </Grid>
          <Grid item xs>
            <Typography>{values.lookupName}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Divider />
          </Grid>
          <Grid item width="281px">
            <Typography variant="form_label">Upload Files</Typography>
          </Grid>
          <Grid item xs>
            {values.filesUploaded && (
              <FileAttachment
                filename={values.filesUploaded}
                status={values.filesStatus}
                getEndpoint={`${ADMIN_FILE_ENDPOINT}?taskId=${taskId}&filename=${encodeURIComponent(
                  values.filesUploaded
                )}`}
                width={INPUT_WIDTH}
              />
            )}
          </Grid>
        </Grid>
        <CheckerDialog taskType="REFRESH" />
      </FormikForm>
    </Container>
  );
}
