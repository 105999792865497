import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';

type WorkflowHistory = {
  name: string;
  action: string;
  date: string;
};
const columnHelper = createColumnHelper<WorkflowHistory>();
const columns = [
  columnHelper.accessor('name', {
    cell: (info) => info.getValue(),
    header: 'Name',
  }),
  columnHelper.accessor('action', {
    cell: (info) => info.getValue(),
    header: 'Action',
  }),
  columnHelper.accessor('date', {
    cell: (info) => `on 
      ${Intl.DateTimeFormat('en-gb', {
        dateStyle: 'medium',
        timeStyle: 'short',
        hourCycle: 'h12',
        timeZone: 'Singapore',
      }).format(new Date(info.getValue()))}`,
    header: 'Date',
  }),
];
interface Props {
  history: WorkflowHistory[];
}
export default function DataTable({ history }: Props) {
  const table = useReactTable({
    data: history,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <Table>
      <TableHead sx={{ bgcolor: '#F4F4F4' }}>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id} sx={{ px: 1, py: '8px' }}>
            {headerGroup.headers.map((header) => (
              <TableCell key={header.id} sx={{ px: 1, py: '8px' }}>
                <Typography variant="table_header">
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id} sx={{ verticalAlign: 'top', p: 1, py: '16px' }}>
                <Typography>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Typography>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
