import { Search } from '@mui/icons-material';
import {
  Autocomplete,
  MenuItem,
  SxProps,
  TextField,
  Typography,
  autocompleteClasses,
  capitalize,
  styled,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ErrorMessage, useFormikContext } from 'formik';
import pluralize from 'pluralize';
import { useSearchParams } from 'react-router-dom';
import { Form, FormRow } from '../../../components/Form';
import { FileAttachment } from '../../../components/Form/Attachment';
import DatePickerInput from '../../../components/Form/DateInput';
import Dropzone from '../../../components/Form/Dropzone';
import SelectInput from '../../../components/Form/Select';
import { TASK_FILE_ENDPOINT } from '../../../urls';
import headers from '../../../utils/headers';

const INPUT_WIDTH = '348px';
const AutocompleteStyle: SxProps = {
  width: INPUT_WIDTH,
  justifySelf: 'stretch',
  [`& .${autocompleteClasses.popupIndicator}`]: {
    transform: 'none',
  },
  '& .MuiInputBase-root ': {
    padding: '0px',
    '& .MuiAutocomplete-input': {
      padding: '10px 16px',
    },
  },
};

const ErrorMessageComp = styled(ErrorMessage)(() => ({
  color: '#CC0000',
}));
type ValueTypes = {
  borrowerName: string;
  caseType: 'new' | 'renewal' | undefined;
  docType: 'original' | 'copy' | '';
  filesUploaded: string[];
  filesStatus: string[];
  topDate: Date;
  openDialog: boolean;
};
export default function Create() {
  const { values, handleChange, setFieldValue } = useFormikContext<ValueTypes>();
  const [urlParams] = useSearchParams();
  const taskId = urlParams.get('id') ?? '';
  const { mutate: deleteFile } = useMutation((file: string) =>
    axios.delete(TASK_FILE_ENDPOINT, {
      headers,
      params: { filename: file, taskId },
    })
  );
  return (
    <Form formId="maker-upload-form">
      <FormRow label="Borrower Name">
        <Autocomplete
          disablePortal
          freeSolo
          autoSelect
          onChange={(e, value) => {
            setFieldValue('borrowerName', value);
          }}
          id="borrowerName"
          options={[]}
          value={values.borrowerName}
          popupIcon={<Search />}
          sx={AutocompleteStyle}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                sx: { color: '#999999' },
              }}
              placeholder="Search by Counterparty or GCIN "
            />
          )}
        />
        <ErrorMessageComp name="borrowerName" component={Typography} />
      </FormRow>
      <FormRow label="Type of Case">
        <SelectInput
          name="caseType"
          value={values.caseType}
          width={INPUT_WIDTH}
          handleChange={handleChange}
          renderValue={(value) => (value ? `${capitalize(value)} Case` : 'Select')}
        >
          <MenuItem value="new">New Case</MenuItem>
          <MenuItem value="renewal">Renewal Case</MenuItem>
        </SelectInput>
        <ErrorMessageComp name="Case Type" component={Typography} />
      </FormRow>
      <FormRow label="Type of Document">
        <SelectInput
          value={values.docType}
          name="docType"
          handleChange={handleChange}
          width={INPUT_WIDTH}
          renderValue={(value) => (value ? capitalize(value) : 'Select')}
        >
          <MenuItem value="original">Original</MenuItem>
          <MenuItem value="copy">Copy</MenuItem>
        </SelectInput>
        <ErrorMessageComp name="docType" component={Typography} />
      </FormRow>
      {values.caseType === 'new' && (
        <FormRow label="TOP/ Loan Disbursement Date">
          <DatePickerInput name="topDate" />
          <ErrorMessageComp name="topDate" component={Typography} />
        </FormRow>
      )}
      <FormRow label="Upload Files">
        <Typography marginBottom="8px" fontWeight={700}>
          {values.filesUploaded.length === 0
            ? 'No file'
            : pluralize('file', values.filesUploaded.length, true)}{' '}
          attached
        </Typography>
        {values.filesUploaded.map((file, index) => (
          <FileAttachment
            filename={file}
            status={values.filesStatus[index]}
            width={INPUT_WIDTH}
            getEndpoint={`${TASK_FILE_ENDPOINT}?taskId=${taskId}&filename=${encodeURIComponent(
              file
            )}`}
            key={file}
            handleDelete={() => {
              setFieldValue(
                'filesUploaded',
                values.filesUploaded.filter((f) => f !== file)
              );
              setFieldValue(
                'filesStatus',
                values.filesStatus.filter((f) => f !== file)
              );
              deleteFile(file);
            }}
          />
        ))}
        <Dropzone
          filesStatus={values.filesStatus}
          files={values.filesUploaded}
          uploadUrl={TASK_FILE_ENDPOINT}
          taskId={taskId}
        />
        <ErrorMessageComp name="filesUploaded" component={Typography} />
      </FormRow>
    </Form>
  );
}
