import { Container, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import CheckerToolbar from '../../components/toolbars/ChekerToolbar';
import MakerToolbar from '../../components/toolbars/MakerToolbar';
import RemoveReview from '../../pages/admin/checker/Remove';
import RemovePage from '../../pages/admin/maker/Remove';
import { useAppSelector } from '../../redux/hooks';
import ViewToolbar from './ViewToolbar';
import { QueryResponse } from './interfaces';

const TaskIDBar = {
  bgcolor: '#FFF',
  minWidth: 'calc(100vw - 64px)',
  marginY: '8px',
  paddingY: '16px',
};
const validatationSchema = Yup.object().shape({
  removedItems: Yup.array().min(1, 'Please select at least one item'),
});
interface IHistory {
  action: string;
  name: string;
  date: string;
}
export default function Remove({
  data,
  checker,
  history,
  maker,
}: {
  data: QueryResponse['data'];
  checker?: boolean;
  history: IHistory[];

  maker: string;
}) {
  const [urlParams] = useSearchParams();
  const taskID = urlParams.get('id');
  const removedItems =
    data
      ?.filter((item) => item.keywordId !== null)
      ?.map(({ checklistItem, documentName, keyword, keywordId, lookupName }) =>
        JSON.stringify({ keywordId, checklistItem, documentName, lookupName, keyword })
      ) ?? [];
  const view = useAppSelector(
    (state) => state.taskStore.authLevel === 1 || (state.taskStore.username === maker && checker)
  );
  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={validatationSchema}
      initialValues={{
        removedItems,
        openDialog: false,
        openReject: false,
        openReturn: false,
        openApprove: false,
      }}
      onSubmit={() => {}}
    >
      <>
        {view && <ViewToolbar />}
        {!view &&
          (checker ? (
            <CheckerToolbar history={history[history.length - 1]} />
          ) : (
            <MakerToolbar taskType="DELETE" history={history[history.length - 1]} />
          ))}
        <Container sx={TaskIDBar}>
          <Typography variant="h1" fontWeight={700}>
            Task ID {taskID}
          </Typography>
        </Container>

        {view && <RemoveReview data={data} />}
        {!view && (checker ? <RemoveReview data={data} /> : <RemovePage />)}
      </>
    </Formik>
  );
}
Remove.defaultProps = {
  checker: false,
};
