import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';

interface ICollateralData {
  collateralId: string;
  collateralType: string;
  riskSituation: string;
  machineryNo: string;
  bankReferenceNo: number;
  insuranceType: string;
  policyAmount: string;
  riskCoverEndDate: string;
  appraisedValue: string;
  inspectionAppraisalType: number;
  latestInspectionAppraisalDate: string;
  ownerNames: string;
}
interface IChecklistHeaderProps {
  collateralId: string;
  allCollateralData: {
    [key: string | number]: ICollateralData;
  };
  gcinLabel: string;
}

interface IInsuranceInformationProp {
  collateralData: ICollateralData;
}

const h2Style = { mt: '16px', mb: '24px' };

function InsuranceInformation({ collateralData }: IInsuranceInformationProp) {
  return (
    <>
      <Box sx={h2Style}>
        <Typography variant="checklist_header_h2">Insurance Information</Typography>
      </Box>
      <Grid container spacing="8px">
        <Grid item width="281px">
          <Typography variant="checklist_header_key">Bank Reference No</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">{collateralData.bankReferenceNo}</Typography>
        </Grid>
        <Grid item xs={12} />
        <Grid item width="281px">
          <Typography variant="checklist_header_key">Insurance Type</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">{collateralData.insuranceType}</Typography>
        </Grid>
        <Grid item xs={12} />
        <Grid item width="281px">
          <Typography variant="checklist_header_key">Policy Amount</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">{collateralData.policyAmount}</Typography>
        </Grid>
        <Grid item xs={12} />
        <Grid item width="281px">
          <Typography variant="checklist_header_key">Risk Cover End Date</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">
            {collateralData.riskCoverEndDate}
          </Typography>
        </Grid>
        <Grid sx={{ mt: '8px' }} item xs={12} />
      </Grid>
    </>
  );
}

interface IPropertyInformationProps {
  collateralId: string;
  collateralData: ICollateralData;
  gcinLabel: string;
}
function PropertyInformation({
  collateralId,
  collateralData,
  gcinLabel,
}: IPropertyInformationProps) {
  return (
    <>
      <Box sx={h2Style}>
        <Typography variant="checklist_header_h2">Property Information</Typography>
      </Box>

      <Grid container spacing="8px">
        <Grid item width="281px">
          <Typography variant="checklist_header_key">Borrower Name (GCIN)</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">{gcinLabel}</Typography>
        </Grid>
        <Grid item xs={12} />

        <Grid item width="281px">
          <Typography variant="checklist_header_key">Collateral ID</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">{collateralId}</Typography>
        </Grid>
        <Grid item xs={12} />

        <Grid item width="281px">
          <Typography variant="checklist_header_key">Addresses</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">{collateralData.riskSituation}</Typography>
        </Grid>
        <Grid item xs={12} />

        <Grid item width="281px">
          <Typography variant="checklist_header_key">Collateral Valuation Amount</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">{collateralData.appraisedValue}</Typography>
        </Grid>
        <Grid item xs={12} />

        <Grid item width="281px">
          <Typography variant="checklist_header_key">Collateral Valuation Date</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">
            {collateralData.latestInspectionAppraisalDate}
          </Typography>
        </Grid>
        <Grid item xs={12} />

        <Grid item width="281px">
          <Typography variant="checklist_header_key">Collateral Valuation Type</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">
            {collateralData.inspectionAppraisalType}
          </Typography>
        </Grid>
        <Grid item xs={12} />

        <Grid item width="281px">
          <Typography variant="checklist_header_key">CLS Collateral Owner GCIN / Name</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">{collateralData.ownerNames}</Typography>
        </Grid>
        <Grid sx={{ mt: '8px' }} item xs={12} />
      </Grid>
    </>
  );
}

function MachinaryInformation({
  collateralId,
  collateralData,
  gcinLabel,
}: IPropertyInformationProps) {
  return (
    <>
      <Box sx={h2Style}>
        <Typography variant="checklist_header_h2">Machinery Information</Typography>
      </Box>

      <Grid container spacing="8px">
        <Grid item width="281px">
          <Typography variant="checklist_header_key">Borrower Name (GCIN)</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">{gcinLabel}</Typography>
        </Grid>
        <Grid item xs={12} />

        <Grid item width="281px">
          <Typography variant="checklist_header_key">Collateral ID</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">{collateralId}</Typography>
        </Grid>
        <Grid item xs={12} />

        <Grid item width="281px">
          <Typography variant="checklist_header_key">Machinery Model</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">{collateralData.riskSituation}</Typography>
        </Grid>
        <Grid item xs={12} />

        <Grid item width="281px">
          <Typography variant="checklist_header_key">Machinery No.</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">{collateralData.machineryNo}</Typography>
        </Grid>
        <Grid item xs={12} />

        <Grid item width="281px">
          <Typography variant="checklist_header_key">CLS Collateral Owner GCIN / Name</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="checklist_header_value">{collateralData.ownerNames}</Typography>
        </Grid>
        <Grid sx={{ mt: '8px' }} item xs={12} />
      </Grid>
    </>
  );
}

function ChecklistHeader({ collateralId, allCollateralData, gcinLabel }: IChecklistHeaderProps) {
  const [collateralData] = React.useState(allCollateralData[collateralId]);

  if (collateralData.collateralType === 'Property') {
    return (
      <>
        <PropertyInformation
          collateralData={collateralData}
          collateralId={collateralId}
          gcinLabel={gcinLabel}
        />
        <Divider />
        <InsuranceInformation collateralData={collateralData} />
        <Divider />
      </>
    );
  }
  return (
    <>
      <MachinaryInformation
        collateralData={collateralData}
        collateralId={collateralId}
        gcinLabel={gcinLabel}
      />
      <Divider />
      <InsuranceInformation collateralData={collateralData} />
      <Divider />
    </>
  );
}

export default ChecklistHeader;
