import { Typography, capitalize } from '@mui/material';
import { useFormikContext } from 'formik';
import pluralize from 'pluralize';
import { useSearchParams } from 'react-router-dom';
import { Form, FormRow } from '../../../components/Form';
import { FileAttachment } from '../../../components/Form/Attachment';
import { TASK_FILE_ENDPOINT } from '../../../urls';
import { ValueType } from '../interface';

const INPUT_WIDTH = '348px';

const label = {
  borrowerName: 'Borrower Name',
  caseType: 'Type of Case',
  docType: 'Type of Document',
  topDate: 'Top Date',
  filesUploaded: 'Files Uploaded',
  insuredName: 'Insured Name',
};
function dateToString(date: string) {
  return Intl.DateTimeFormat('en-gb', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    timeZone: 'Singapore',
  }).format(new Date(date));
}
export default function ViewInfo() {
  const { values } = useFormikContext<ValueType>();
  const [urlParams] = useSearchParams();
  const taskId = urlParams.get('id') ?? '';
  return (
    <Form formId="maker-upload-form">
      {Object.keys(values)
        // Filter out fields without values
        .filter((v) => values[v as keyof typeof values])
        // Filter out fields without their own labels
        .filter(
          (v) =>
            v !== 'filesStatus' &&
            v !== 'openDialog' &&
            v !== 'checklistItems' &&
            v !== 'gcinLabel' &&
            v !== 'gcin'
        )
        // Filter out fields that are not needed for renewal
        .filter((v) => (values.caseType === 'renewal' ? v !== 'topDate' : v))
        .map((value) => (
          <FormRow key={value} label={label[value as keyof typeof label]}>
            {value === 'filesUploaded' ? (
              <>
                <Typography marginBottom="8px" fontWeight={700}>
                  {pluralize('file', values.filesUploaded.length, true)} attached
                </Typography>
                {values.filesUploaded.map((file, index) => (
                  <FileAttachment
                    filename={file}
                    status={values.filesStatus[index]}
                    width={INPUT_WIDTH}
                    getEndpoint={`${TASK_FILE_ENDPOINT}?taskId=${taskId}&filename=${file}`}
                    key={file}
                  />
                ))}
              </>
            ) : (
              <Typography>
                {value === 'topDate' &&
                  dateToString(values[value as keyof typeof values] as string)}
                {value === 'caseType' &&
                  `${capitalize(values[value as keyof typeof values] as string)} Case`}
                {value === 'docType' &&
                  `${capitalize(values[value as keyof typeof values] as string)}`}
                {value === 'borrowerName' && `${values[value as keyof typeof values]}`}
                {value === 'insuredName' && `${values[value as keyof typeof values]}`}
              </Typography>
            )}
            {value === 'insuredName' && (
              <Typography key={values.gcin} marginBottom="8px">
                {values.gcinLabel}
              </Typography>
            )}
          </FormRow>
        ))}
    </Form>
  );
}
