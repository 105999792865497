import { ArrowBack } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ViewToolbar from '../../components/toolbars/ViewToolbar';

export default function Toolbar() {
  return (
    <ViewToolbar>
      <Button component={Link} startIcon={<ArrowBack />} sx={{ color: '#000' }} to="/admin">
        <Typography fontWeight={600} fontSize="16px" lineHeight="16px" noWrap width="75px">
          Task List
        </Typography>
      </Button>
    </ViewToolbar>
  );
}
