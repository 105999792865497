import { Add, AddCircle, Close, Remove } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { CellContext } from '@tanstack/react-table';
import axios from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { FileAttachment, LinkAttachment } from '../../../components/Form/Attachment';
import Dropzone from '../../../components/Form/Dropzone';
import {
  REMARKS_CREATE_ENDPOINT,
  REMARKS_UPDATE_ENDPOINT,
  REMARK_FILE_ENDPOINT,
} from '../../../urls';
import headers from '../../../utils/headers';
import { IChecklist, IRemarks } from '../interface';
import { remarkValidation } from '../validations';

interface ValueType {
  remarkType: string | undefined;
  remarkText: string | undefined;
  remarkLink: string | undefined;
  filesUploaded: string | undefined;
  filesStatus: string;
}

interface AttachLinkProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (link: string) => void;
}

function AttachLink({ open, handleClose, handleSubmit }: AttachLinkProps) {
  const [link, setLink] = React.useState('');
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      slotProps={{ backdrop: { sx: { backgroundColor: 'rgba(0, 0, 0, 0.7)' } } }}
      PaperProps={{ sx: { maxWidth: '680px', padding: '24px' } }}
    >
      <DialogTitle sx={{ p: 0, display: 'flex', justifyContent: 'space-between' }}>
        <Typography fontWeight={400} fontSize="24px">
          Attach Link
        </Typography>
        <IconButton
          onClick={handleClose}
          data-testid="close-dialog"
          size="small"
          sx={{ p: 0, width: '24px', height: '24px' }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0,
          marginY: '30px',
          width: '100%',
          paddingRight: '8px',
        }}
      >
        <DialogContentText color="#222222" display="inline" fontWeight={700}>
          Add Document URL
        </DialogContentText>
        <TextField
          sx={{ display: 'block' }}
          fullWidth
          role="textbox"
          name="url"
          onChange={(e) => {
            setLink(e.target.value);
          }}
          placeholder="URL"
          InputProps={{
            sx: {
              margin: '8px 0px',
              height: '40px',
            },
            inputProps: {
              style: {
                padding: '8px',
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ paddingRight: '8px', height: 'fit-content' }}>
        <Button
          onClick={() => handleSubmit(link)}
          variant="contained"
          sx={{ p: '9px 16px', width: '149px' }}
        >
          <Typography fontWeight={700}>Attach</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const validationSchema = remarkValidation;

const width = '238px';
function CreateRemarkRequest(
  values: ValueType,
  taskId: string,
  assetId: string,
  checklistItem: number
) {
  const { remarkType, remarkText, filesUploaded, remarkLink } = values;
  return axios
    .put(
      REMARKS_CREATE_ENDPOINT,
      {
        taskId,
        assetId,
        checklistItem,
        remarkType: remarkType ?? 'NA',
        remarkText,
        remarkFile: filesUploaded,
        remarkLink,
      },
      { headers }
    )
    .then((res) => res.data);
}
function UpdateRemarkRequest(values: ValueType, remarkId?: number) {
  const { remarkType, remarkText, filesUploaded, remarkLink } = values;
  return axios
    .post(
      REMARKS_UPDATE_ENDPOINT,
      {
        remarkId,
        remarkType: remarkType ?? 'NA',
        remarkText,
        remarkFile: filesUploaded,
        remarkLink,
      },
      { headers }
    )
    .then((res) => res.data);
}
const initialValues: (
  remarkType?: string,
  remarkText?: string,
  remarkLink?: string,
  remarkFile?: string
) => ValueType = (
  remarkType?: string,
  remarkText?: string,
  remarkLink?: string,
  remarkFile?: string
) => ({
  remarkType,
  remarkText: remarkText ?? '',
  remarkLink: remarkLink ?? '',
  filesUploaded: remarkFile,
  filesStatus: remarkFile ? 'success' : '',
});
const selectValue = {
  NOT_APPLICABLE: 'Not Applicable',
  WAIVED: 'Waived',
  COMPLIED: 'Complied',
  NOT_COMPLIED: 'Not Complied',
};

interface RemarksProps {
  info: CellContext<IChecklist, IRemarks[] | null>;
  taskId: string;
  view?: boolean;
}
interface ISelectInput {
  name: string;
  children: React.ReactNode;
  value?: string;
  handleChange?: (event: SelectChangeEvent, child: React.ReactNode) => void;
}
function SelectInput({ name, value, handleChange, children }: ISelectInput) {
  return (
    <Select
      value={value}
      id={name}
      name={name}
      displayEmpty
      renderValue={(v) => selectValue[v as keyof typeof selectValue] ?? 'Select'}
      onChange={handleChange}
      sx={{
        color: value ? '000000' : '#999999',
        width,
      }}
      SelectDisplayProps={{
        style: {
          padding: '10px 16px',
        },
      }}
    >
      {children}
    </Select>
  );
}
SelectInput.defaultProps = {
  value: undefined,
  handleChange: undefined,
};

interface IFormikForm {
  formikProps: FormikProps<ValueType>;
  formEdit: boolean;
  openDelete: boolean;
  setOpenDelete: (open: boolean) => void;
  handleDelete: (resetForm: () => void) => void;
  editable: boolean;
  info: CellContext<IChecklist, IRemarks[] | null>;
  remarkDate?: string;
}
const remarkTypeText = {
  NA: '',
  NOT_APPLICABLE: 'Not Applicable',
  WAIVED: 'Waived',
  COMPLIED: 'Complied',
  NOT_COMPLIED: 'Not Complied',
};
function FormikForm({
  formikProps,
  formEdit,
  openDelete,
  setOpenDelete,
  handleDelete,
  editable,
  info,
  remarkDate,
}: IFormikForm) {
  const { values, setFieldValue, handleChange, resetForm } = formikProps;
  const [open, setOpen] = React.useState(false);
  const { mutate } = useMutation((filename: string) =>
    axios
      .delete(REMARK_FILE_ENDPOINT, {
        params: {
          taskId: info.taskId,
          filename: encodeURIComponent(filename),
          assetId: info.assetId,
          checkListItem: info.row.index + 1,
        },
        headers,
      })
      .then((res) => res.data)
  );
  return (
    <Form
      id={`maker-remark-form-${info.row.index}-${info.assetId}`}
      className={`maker-remark-forms-${info.assetId}`}
    >
      <Stack width="100%" spacing="8px">
        {!formEdit ? (
          <Typography>
            {remarkTypeText[values.remarkType as keyof typeof remarkTypeText]}
          </Typography>
        ) : (
          <SelectInput
            name="remarkType"
            value={values.remarkType === 'NA' ? undefined : values.remarkType}
            handleChange={handleChange}
          >
            <MenuItem value="NOT_APPLICABLE">Not Applicable</MenuItem>
            <MenuItem value="WAIVED">Waived</MenuItem>
            <MenuItem value="COMPLIED">Complied</MenuItem>
            <MenuItem value="NOT_COMPLIED">Not Complied</MenuItem>
          </SelectInput>
        )}
        {!formEdit ? (
          <Typography>{values.remarkText}</Typography>
        ) : (
          <TextField
            sx={{ display: 'block' }}
            multiline
            value={values.remarkText}
            id="remarkText"
            role="textbox"
            name="remarkText"
            minRows={4}
            onChange={handleChange}
            placeholder="Please Justify"
            InputProps={{
              sx: {
                minHeight: '100px',
                width,
              },
            }}
          />
        )}
        {values.remarkLink && (
          <LinkAttachment
            width={width}
            link={values.remarkLink}
            handleDelete={
              editable
                ? () => {
                    setFieldValue('remarkLink', undefined);
                  }
                : undefined
            }
          />
        )}
        {formEdit && (
          <Button
            role="button"
            onClick={() => setOpen(true)}
            sx={{ maxWidth: width, justifyContent: 'start' }}
          >
            <AddCircle fontSize="small" />
            <Typography marginLeft="8px" marginY="auto">
              Attach Link
            </Typography>
          </Button>
        )}
        {values.filesUploaded && (
          <FileAttachment
            filename={values.filesUploaded}
            status="success"
            width={width}
            getEndpoint={`${REMARK_FILE_ENDPOINT}?taskId=${
              info.taskId
            }&filename=${encodeURIComponent(values.filesUploaded)}&assetId=${
              info.assetId
            }&checkListItem=${info.row.index + 1}`}
            key={values.filesUploaded}
            handleDelete={
              editable
                ? () => {
                    setFieldValue('filesUploaded', undefined);
                    mutate(values.filesUploaded as string);
                  }
                : undefined
            }
          />
        )}
        <AttachLink
          open={open}
          handleClose={() => setOpen(false)}
          handleSubmit={(link) => {
            setOpen(false);
            setFieldValue('remarkLink', link);
          }}
        />
        {remarkDate && (
          <Typography fontSize="12px" color="#777777" margin="8px 0 16px 0 !important">
            Generated by {info.getValue()?.[0]?.maker} on{' '}
            {Intl.DateTimeFormat('en-gb', {
              dateStyle: 'medium',
              timeZone: 'Singapore',
            }).format(new Date(remarkDate))}
          </Typography>
        )}
      </Stack>
      {formEdit && (
        <Dropzone
          filesStatus={values.filesStatus}
          files={values.filesUploaded as string}
          checkListItem={info.row.index + 1}
          uploadUrl={REMARK_FILE_ENDPOINT}
          taskId={info.taskId}
          assetId={info.assetId ?? 'test'}
        />
      )}
      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        fullWidth
        slotProps={{ backdrop: { sx: { backgroundColor: 'rgba(0, 0, 0, 0.7)' } } }}
        PaperProps={{ sx: { maxWidth: '680px', padding: '24px' } }}
      >
        <DialogTitle sx={{ p: 0 }} fontWeight={400} fontSize="24px">
          Delete this Remark?
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <DialogContentText color="#222222" fontWeight={700} margin="16px 0px 8px 0px ">
            Are you sure you want to delete this remark?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
          <Button
            onClick={() => handleDelete(resetForm)}
            variant="contained"
            sx={{ p: '9px 16px', width: '149px' }}
          >
            <Typography fontWeight={700}>Delete</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </Form>
  );
}
FormikForm.defaultProps = {
  remarkDate: undefined,
};
type MyMutationFn<T> = UseMutateFunction<any, unknown, T>;

interface Mutations {
  delete: MyMutationFn<void>;
  create: MyMutationFn<ValueType>;
  update: MyMutationFn<ValueType>;
}

function handleRemarkSubmit({ update, create }: Mutations, values: ValueType, remarkId?: number) {
  if (remarkId) {
    update(values);
  } else {
    create(values);
  }
}
function handleRemarkDelete(mutation: Mutations) {
  mutation.delete();
}
function handleEdit(
  setEdit: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>,
  remarkId?: number,
  edit?: boolean
) {
  if (remarkId === undefined) {
    setEdit(!edit);
  }
  if (remarkId) {
    setOpenDelete(true);
  }
}
function NewLineParser(string: string) {
  return string.split('\n').map((text) => <Typography key={text}>{text}</Typography>);
}
function IconStyle(formEdit?: boolean) {
  return {
    padding: 0,
    border: '2px solid grey',
    borderRadius: '5px',
    marginLeft: () => (formEdit ? '8px' : 'auto'),
  };
}

const remarkFunction: (values: ValueType, id: number, maker: string) => IRemarks = (
  { remarkType, remarkText, filesUploaded, remarkLink },
  id,
  maker
) => ({
  remarkType: remarkType ?? '',
  remarkText: remarkText ?? '',
  remarkLink: remarkLink ?? '',
  id,
  maker,
  created: new Date().toISOString(),
  remarkFile: filesUploaded ?? '',
});
export default function RemarksComponent({ info, info: { editable }, taskId, view }: RemarksProps) {
  const [edit, setEdit] = React.useState(info.editable);
  const [remark, setRemark] = React.useState<IRemarks | undefined>(
    info.getValue()?.[0] ?? undefined
  );
  const formEdit = Boolean(edit || Boolean(remark?.id)) && Boolean(editable);
  const [openDelete, setOpenDelete] = React.useState(false);
  React.useEffect(() => {
    if (!editable) {
      setEdit(false);
    }
  }, [editable, setEdit]);

  const remarkCreate = useMutation({
    mutationFn: (values: ValueType) =>
      CreateRemarkRequest(values, taskId, info.assetId, info.row.index + 1),
    onSuccess: (data, values) => {
      setRemark(remarkFunction(values, data, info.maker));
    },
  });
  const remarkUpdate = useMutation({
    mutationFn: (values: ValueType) => UpdateRemarkRequest(values, remark?.id),
    onSuccess: (data, values) => {
      setRemark(remarkFunction(values, data, info.maker));
    },
  });
  const remarkDelete = useMutation({
    mutationFn: () => axios.delete(`${REMARKS_CREATE_ENDPOINT}/${remark?.id}`, { headers }),
    onSuccess: () => {
      setRemark(undefined);
      setOpenDelete(false);
    },
  });
  const mutations = {
    delete: remarkDelete.mutate,
    create: remarkCreate.mutate,
    update: remarkUpdate.mutate,
  };
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="start">
      <Stack width="100%" spacing="8px">
        {!view ? (
          <Formik
            enableReinitialize
            validateOnMount
            validateOnChange
            initialValues={initialValues(
              remark?.remarkType,
              remark?.remarkText,
              remark?.remarkLink,
              remark?.remarkFile
            )}
            onSubmit={(values) => handleRemarkSubmit(mutations, values, remark?.id)}
            validationSchema={validationSchema}
          >
            {(formikProps) => (
              <FormikForm
                formikProps={formikProps}
                formEdit={formEdit}
                openDelete={openDelete}
                setOpenDelete={setOpenDelete}
                handleDelete={() => handleRemarkDelete(mutations)}
                editable={Boolean(editable)}
                info={info}
                remarkDate={remark?.created}
              />
            )}
          </Formik>
        ) : (
          remark?.id && (
            <>
              <Typography>
                {remarkTypeText[remark.remarkType as keyof typeof remarkTypeText]}
              </Typography>
              <Typography>{remark.remarkText}</Typography>
              {remark.remarkLink && <LinkAttachment width={width} link={remark.remarkLink} />}
              {remark.remarkFile && (
                <FileAttachment
                  filename={remark.remarkFile}
                  status="success"
                  width={width}
                  getEndpoint={`${REMARK_FILE_ENDPOINT}?taskId=${
                    info.taskId
                  }&filename=${encodeURIComponent(remark.remarkFile)}&assetId=${
                    info.assetId
                  }&checkListItem=${info.row.index + 1}`}
                  key={remark.remarkFile}
                />
              )}
              {remark.created && (
                <Typography fontSize="12px" color="#777777" margin="8px 0 16px 0 !important">
                  Generated by {info.getValue()?.[0]?.maker} on{' '}
                  {Intl.DateTimeFormat('en-gb', {
                    dateStyle: 'medium',
                    timeZone: 'Singapore',
                  }).format(new Date(remark.created))}
                </Typography>
              )}
            </>
          )
        )}
        {NewLineParser(info.row.original.sourceAValue).map((text) => text)}
        {NewLineParser(info.row.original.sourceBValue).map((text) => text)}
        {(info.row.original.sourceAValue || info.row.original.sourceBValue) && (
          <Typography fontSize="12px" color="#777777">
            Autogenerated by {info.maker} on{' '}
            {Intl.DateTimeFormat('en-gb', {
              dateStyle: 'medium',
              timeZone: 'Singapore',
            }).format(new Date(info.uploadTime))}
          </Typography>
        )}
      </Stack>
      {editable && (
        <IconButton
          size="small"
          sx={IconStyle(formEdit)}
          onClick={() => handleEdit(setEdit, setOpenDelete, remark?.id, edit)}
          data-testid="remark-add-delete"
        >
          {formEdit ? <Remove /> : <Add />}
        </IconButton>
      )}
    </Stack>
  );
}
RemarksComponent.defaultProps = {
  view: false,
};
