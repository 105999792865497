import {
  Box,
  Button,
  List,
  ListItem,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  RowData,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import { IChecklist, IChecklistArray } from '../interface';
import requirements from './checklistItems';
import RemarksComponent from './checklistRemarks';

declare module '@tanstack/react-table' {
  interface CellContext<TData extends RowData, TValue> {
    TData: TData;
    TValue: TValue;
    assetId: string;
    maker: string;
    uploadTime: string;
    taskId: string;
    editable?: boolean;
    view?: boolean;
  }
}
interface ChecklistTableProps {
  checklist?: IChecklistArray;
  maker: string;
  uploadTime: string;
}

const ChipStyle: SxProps = {
  lineHeight: '14px',
  fontSize: '10px',
  textAlign: 'center',
  fontWeight: '700',
  color: '#FFF',
  textTransform: 'uppercase',
  textSizeAdjust: 'none',
  paddingY: '2px',
  borderRadius: '2px',
};
const CompliedChipStyle: SxProps = {
  ...ChipStyle,
  backgroundColor: '#0CAF4E',
  width: '69px',
};
const NotCompliedChipStyle: SxProps = {
  ...ChipStyle,
  backgroundColor: '#CC0000',
  width: '93px',
};
const NotApplicableChipStyle: SxProps = {
  ...ChipStyle,
  whiteSpace: 'nowrap',
  borderRadius: '0px',
  backgroundColor: '#777777',
  width: '93px',
};
interface DocumentTextProps {
  document: 'A' | 'B';
  row: number;
}
function DocumentText({ document, row }: DocumentTextProps) {
  const policySchedule = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => 'Policy Schedule');
  const caseType = useAppSelector((state) => state.taskStore.caseType);
  const USER_INPUT = 'User Input';
  const PRE_DEFINED_LIST = 'Pre-defined List';
  const FROM_CLS = 'From CLS';
  const text =
    caseType === 'New Cases'
      ? {
          A: ['-', ...policySchedule],
          B: [
            USER_INPUT,
            '-',
            '-',
            'Valuation Report',
            USER_INPUT,
            USER_INPUT,
            PRE_DEFINED_LIST,
            '-',
            '-',
            '-',
            'Valuation Report',
          ],
        }
      : {
          A: ['-', ...policySchedule],
          B: [
            USER_INPUT,
            FROM_CLS,
            '-',
            FROM_CLS,
            FROM_CLS,
            PRE_DEFINED_LIST,
            '-',
            '-',
            '-',
            '-',
            FROM_CLS,
          ],
        };
  return <Typography>{text[document][row]}</Typography>;
}

const columnHelper = createColumnHelper<IChecklist>();
const columns = [
  columnHelper.display({
    cell: (info) => (
      <Stack direction="row" gap="4px" sx={{ maxWidth: '500px', fontWeight: 700 }}>
        {String.fromCharCode(info.row.index + 97)}.
        <Box>
          <Typography fontWeight={700} sx={{ maxWidth: '479px' }}>
            {requirements[info.row.index].text}
          </Typography>
          {requirements[info.row.index].list && (
            <List
              sx={{
                listStyleType: 'disc',
                pl: 2,
                margin: 0,
              }}
            >
              {requirements[info.row.index].list?.map((item, index) => (
                <ListItem
                  key={`${JSON.stringify(item)} ${index.toString()}`}
                  sx={{
                    display: 'list-item',
                    p: 0,
                    maxWidth: '463px',
                  }}
                >
                  {item instanceof Object ? (
                    <>
                      {item.text}
                      <List sx={{ listStyleType: 'decimal', padding: 0, pl: 2 }}>
                        {item.list?.map((subItem, subIndex) => (
                          <ListItem
                            key={`${JSON.stringify(item)} ${subIndex.toString()}`}
                            sx={{
                              display: 'list-item',
                              p: 0,
                              counterIncrement: 'list',
                              '::marker': {
                                content: 'counter(list) ") "',
                              },
                              maxWidth: '463px',
                            }}
                          >
                            {JSON.stringify(subItem).replace(/"/g, '')}
                          </ListItem>
                        ))}
                      </List>
                    </>
                  ) : (
                    item
                  )}
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Stack>
    ),
    header: 'Requirements',
  }),
  columnHelper.accessor('complied', {
    cell: (info) => (
      <>
        {info.getValue() === 'COMPLIED' && <Typography sx={CompliedChipStyle}>COMPLIED</Typography>}
        {info.getValue() === 'NOT COMPLIED' && (
          <Typography sx={NotCompliedChipStyle}>NOT COMPLIED</Typography>
        )}
        {info.getValue() === 'NOT APPLICABLE' && (
          <Typography sx={NotApplicableChipStyle}>NOT APPLICABLE</Typography>
        )}
      </>
    ),
    header: 'Status',
  }),
  columnHelper.accessor('sourceAValue', {
    cell: (info) => <DocumentText document="A" row={info.row.index} />,
    header: 'Source (A)',
  }),
  columnHelper.accessor('sourceBValue', {
    cell: (info) => <DocumentText document="B" row={info.row.index} />,
    header: 'Source (B)',
  }),
  columnHelper.accessor('remarks', {
    cell: (info) => (
      <RemarksComponent
        info={info}
        taskId={info.taskId}
        view={info.view}
        key={`${info.assetId}_${info.taskId}_${info.row.index}`}
      />
    ),
    header: 'Remarks',
  }),
];

export default function ChecklistTable({ checklist, maker, uploadTime }: ChecklistTableProps) {
  const view = useAppSelector((state) => state.taskStore.authLevel === 1);
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get('id');
  const location = useLocation();
  const [editable, setEditable] = React.useState(false);
  const table = useReactTable({
    data: checklist?.assetChecklist ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  // function to set the width of the columns
  function cellStyle(index: number) {
    const padding = {
      0: '8px 0 8px 16px',
    };
    const width = {
      0: '516px',
      1: '130px',
    };
    return {
      verticalAlign: 'top',
      p: padding[index as keyof typeof padding] ?? '8px 0 8px 0',
      width: width[index as keyof typeof width] ?? '200px',
    };
  }
  function handleDone() {
    const forms = document.getElementsByClassName(
      `maker-remark-forms-${checklist?.assetId}`
    ) as HTMLCollectionOf<HTMLFormElement>;
    Array.from(forms).forEach((form) => form.requestSubmit());
    setEditable(!editable);
  }
  if (!checklist) {
    return null;
  }
  return (
    <>
      <Stack direction="row" marginY="16px" paddingBottom="8px">
        <Typography fontWeight={700} fontSize="20px" marginY="auto">
          Result from NLP
        </Typography>
        {!location.pathname.startsWith('/task/completed') && !view && (
          <Button
            variant="contained"
            color="inherit"
            type="submit"
            form="maker-remark-form"
            formAction="submit"
            onClick={editable ? () => handleDone() : () => setEditable(!editable)}
            sx={{
              bgcolor: '#EEEEEE',
              color: '#000000',
              ':disabled': { bgcolor: '#cccccc', color: '#FFF', boxShadow: 2 },
              p: '9px 16px',
              marginLeft: 'auto',
              maxWidth: '128px',
            }}
          >
            <Typography fontWeight={700} marginX="50px">
              {editable ? 'Done' : 'Edit'}
            </Typography>
          </Button>
        )}
      </Stack>
      <Table sx={{ marginTop: '16px', tableLayout: 'fixed' }}>
        <TableHead sx={{ bgcolor: '#F4F4F4' }}>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} sx={{ px: '16px', py: '8px' }}>
              {headerGroup.headers.map((header, index) => (
                <TableCell key={header.id} sx={cellStyle(index)}>
                  <Typography variant="table_header">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row, index) => (
            <TableRow key={row.id}>
              {index >= 11 ? (
                ''
              ) : (
                <>
                  {row.getVisibleCells().map((cell, subIndex) => (
                    <TableCell key={cell.id} sx={{ ...cellStyle(subIndex), p: '16px 16px 16px 0' }}>
                      {flexRender(cell.column.columnDef.cell, {
                        ...cell.getContext(),
                        editable,
                        assetId: checklist?.assetId,
                        maker,
                        uploadTime,
                        taskId: taskId ?? '',
                        view,
                      })}
                    </TableCell>
                  ))}
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
ChecklistTable.defaultProps = {
  checklist: { assetChecklist: [], assetId: '' },
};
