import { Grid } from '@mui/material';
import { Form as FormikForm } from 'formik';
import React from 'react';

interface FormProps {
  formId: string;
  children?: React.ReactNode;
}

export default function Form({ formId, children }: FormProps) {
  return (
    <FormikForm id={formId} role="form" onSubmit={undefined}>
      <Grid container spacing={2} columns={2}>
        {children}
      </Grid>
    </FormikForm>
  );
}
Form.defaultProps = {
  children: null,
};
