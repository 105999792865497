import { Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Row,
  RowData,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import axios from 'axios';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ADMIN_GET_KEYWORDLIST, ADMIN_LIST_CHECKLIST } from '../../urls';
import headers from '../../utils/headers';
import { StaticList } from './interfaces';

declare module '@tanstack/react-table' {
  interface CellContext<TData extends RowData, TValue> {
    TData: TData;
    TValue: TValue;
    rowEditable: boolean;
    setRowEditable: (value: boolean) => void;
    editable?: boolean;
  }
}

interface RowProps {
  data: StaticList[];
  columns: any;
  checklistItem: string;
  editable?: boolean;
  open?: boolean;
}
const ITEM_HEIGHT = 24;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '354px',
    },
  },
};

const buttonStyle = {
  bgcolor: '#EEEEEE',
  color: '#000000',
  ':disabled': { bgcolor: '#cccccc', color: '#FFF', boxShadow: 2 },
  p: '9px 16px',
  minWidth: '128px',
};
export function getChecklistItems() {
  return axios.get(ADMIN_LIST_CHECKLIST, { headers }).then((res) => res.data);
}
export const getAdminKeyword = (list: string) =>
  axios
    .get(ADMIN_GET_KEYWORDLIST, {
      params: { list },
      headers,
    })
    .then((res) => res.data);

function Chips({ label, onDelete }: { label: string; onDelete: () => void }) {
  return (
    <Chip
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      sx={{ borderRadius: '2px', height: '24px' }}
      onDelete={onDelete}
      deleteIcon={<Close fontSize="small" />}
      label={<Typography fontSize="14px">{label}</Typography>}
    />
  );
}
function MultipleSelectChip({
  selected,
  setSelect,
  checklistItems,
}: {
  selected: string[];
  setSelect: (value: string[]) => void;
  checklistItems: string[];
}) {
  const isAllSelected = checklistItems.length > 0 && selected.length === checklistItems.length;
  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;
    if (value[value.length - 1] === 'all') {
      setSelect(selected.length === checklistItems.length ? [] : checklistItems);
      return;
    }
    setSelect(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const { data: checklistItemDescription } = useQuery<IChecklistItems[]>(['getChecklistData'], () =>
    getChecklistItems()
  );
  return (
    <div>
      <FormControl sx={{ m: 1, width: '354px' }}>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={selected}
          onChange={handleChange}
          SelectDisplayProps={{
            style: {
              padding: '8px 16px',
            },
          }}
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={(value) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {value.length === checklistItems.length ? (
                <Chips label="All checklist items" onDelete={() => setSelect([])} />
              ) : (
                selected.map((label) => (
                  <Chips
                    key={label}
                    label={
                      checklistItemDescription?.filter((item) => item.checkListItem === label)[0]
                        ?.description ?? ''
                    }
                    onDelete={() => setSelect(selected.filter((s) => s !== label))}
                  />
                ))
              )}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem value="all" className={isAllSelected ? 'Mui-selected' : ''}>
            <Checkbox
              checked={selected.length === checklistItems.length}
              sx={{
                color: '#999999',
              }}
            />
            All Items
          </MenuItem>
          {checklistItems.map((checklistItem) => (
            <MenuItem key={checklistItem} value={checklistItem}>
              <Checkbox checked={selected.indexOf(checklistItem) > -1} />
              {
                checklistItemDescription?.filter((item) => item.checkListItem === checklistItem)[0]
                  ?.description
              }
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

function RowChild({
  row,
  editable,
  open,
}: {
  row: Row<StaticList>;
  editable: boolean;
  open: boolean;
}) {
  const [rowEditable, setRowEditable] = React.useState<boolean>(false);
  return (
    <TableRow key={row.id}>
      {row.getVisibleCells().map((cell) => (
        <TableCell
          key={cell.id}
          sx={open ? { verticalAlign: 'top', p: 1, height: '58px' } : { p: 0, border: 0 }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {flexRender(cell.column.columnDef.cell, {
              ...cell.getContext(),
              rowEditable,
              setRowEditable,
              editable,
            })}
          </Collapse>
        </TableCell>
      ))}
    </TableRow>
  );
}

interface IChecklistItems {
  checkListItem: string;
  locked: boolean;
  metadata: {
    [index: string]: string[];
  };
  description: string;
}

export const columnHelper = createColumnHelper<StaticList>();

export const checklistCollumns = [
  columnHelper.accessor('checklistItem', {
    cell: () => null,
    header: 'Checklist Item',
  }),
  columnHelper.accessor('documentName', {
    cell: (info) => info.getValue(),
    header: 'Document Name',
  }),
  columnHelper.accessor('lookupName', {
    cell: (info) => info.getValue(),
    header: 'Lookup Name',
  }),
  columnHelper.accessor('keyword', {
    cell: (info) => info.getValue(),
    header: 'Keyword',
  }),
];

export function CollapsibleRow({ data, columns, checklistItem, editable, open }: RowProps) {
  const [openState, setOpenState] = React.useState<boolean>(open ?? false);
  const dataMemo = React.useMemo(() => data ?? [], [data]);
  const columnsMemo = React.useMemo(() => columns ?? [], [columns]);
  const table = useReactTable({
    data: dataMemo,
    columns: columnsMemo,
    getCoreRowModel: getCoreRowModel(),
  });
  const { data: checklistItemDescription } = useQuery<IChecklistItems[]>(['getChecklistData'], () =>
    getChecklistItems()
  );
  return (
    <>
      <TableRow onClick={() => setOpenState(!openState)} sx={{ bgcolor: '#F9F9F9' }}>
        <TableCell sx={{ verticalAlign: 'top', p: '4px', height: '31px' }} colSpan={5}>
          <Stack direction="row" alignItems="center">
            <IconButton aria-label="expand row" size="small">
              {openState ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <Typography fontWeight={700} fontSize="14px">
              {
                checklistItemDescription?.filter((item) => item.checkListItem === checklistItem)[0]
                  ?.description
              }
            </Typography>
          </Stack>
        </TableCell>
      </TableRow>
      {openState &&
        table
          .getRowModel()
          .rows.filter((row) => row.original.checklistItem === checklistItem)
          .map((row) => (
            <RowChild
              row={row}
              editable={editable ?? false}
              key={JSON.stringify(row)}
              open={openState}
            />
          ))}
    </>
  );
}
export function ChecklistItemTable({
  columns,
  checklistItems,
  children,
  edit,
}: {
  columns: any;
  checklistItems: string[];
  children: (checklistItem: string, editable?: boolean) => React.ReactNode;
  edit?: boolean;
}) {
  const [selected, setSelect] = React.useState<string[]>([...checklistItems]);
  const [editable, setEditable] = React.useState<boolean>(false);
  const table = useReactTable({
    data: [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      <Typography fontWeight={700} margin="16px 0 8px 0">
        Checklist Item
      </Typography>
      <Stack
        direction="row"
        justifyContent={edit ? 'space-between' : 'left'}
        sx={{ marginBottom: '16px' }}
      >
        <MultipleSelectChip
          selected={selected}
          setSelect={setSelect}
          checklistItems={checklistItems}
        />

        {edit && (
          <Button
            onClick={() => setEditable(!editable)}
            variant="contained"
            color="inherit"
            sx={buttonStyle}
          >
            <Typography fontWeight={700}>{!editable ? 'Edit' : 'Done'}</Typography>
          </Button>
        )}
      </Stack>
      <TableContainer>
        <Table sx={{ marginBottom: '24px' }}>
          <TableHead sx={{ bgcolor: '#F4F4F4' }}>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell key={header.id} sx={{ p: 1 }}>
                    <Typography
                      variant="table_header"
                      marginRight="1px"
                      color="#222222"
                      sx={{ justifyContent: 'flex-start', textTransform: 'none', padding: 0 }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {selected
              .filter((s) => s !== 'all')
              .map((checklistItem) => children(checklistItem, editable))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

ChecklistItemTable.defaultProps = {
  edit: false,
};
CollapsibleRow.defaultProps = {
  editable: false,
  open: false,
};
