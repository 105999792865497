import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid, Stack, Toolbar, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useFormikContext } from 'formik';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ADMIN_GET_TASK, ADMIN_SAVE_TASK } from '../../urls';
import headers from '../../utils/headers';
import ToolbarHistory from './ToolbarHistory';
import { activeButtonStyle } from './utils';

const buttonStyle = {
  bgcolor: '#EEEEEE',
  color: '#000000',
  ':disabled': { bgcolor: '#cccccc', color: '#FFF', boxShadow: 2 },
  p: '9px 16px',
  minWidth: '128px',
};

function dataFn(taskType: string, data: any) {
  if (taskType === 'ADD') {
    return data.value;
  }
  if (taskType === 'REFRESH') {
    return [
      {
        checklistItem: data.checklistItem,
        lookupName: data.lookupName,
        documentName: data.documentName,
        filename: data.filesUploaded,
      },
    ];
  }
  if (taskType === 'EDIT') {
    return data.data;
  }
  return data.removedItems.map((d: any) => JSON.parse(d));
}
const saveDraft = (taskType: string, taskId: string, data: any) =>
  axios
    .post(
      ADMIN_SAVE_TASK,
      {
        taskId,
        taskType,
        filePath: data.filesUploaded,
        data: dataFn(taskType, data),
      },
      { headers }
    )
    .then((res) => res.data);

interface IHistory {
  action: string;
  name: string;
  date: string;
}
export default function MakerToolbar({
  taskType,
  history,
}: {
  taskType: string;
  history: IHistory;
}) {
  const [urlParams] = useSearchParams();
  const navigate = useNavigate();
  const id = urlParams.get('id') ?? '1';
  const { values, isValid: allowSubmit } = useFormikContext();
  const { mutate } = useMutation(() => saveDraft(taskType, id, values), {
    onSuccess: () => {
      navigate('/admin');
    },
  });
  const { mutate: discardTask } = useMutation(() =>
    axios.delete(`${ADMIN_GET_TASK}/discard/${id}`, { headers }).then(() => navigate('/admin'))
  );
  const { setFieldValue } = useFormikContext();
  return (
    <Box sx={{ backgroundColor: '#FFF', mb: '16px' }}>
      <Toolbar disableGutters sx={{ paddingX: '32px' }}>
        <Button component={Link} startIcon={<ArrowBackIcon />} sx={{ color: '#000' }} to="/admin">
          <Typography fontWeight={600} fontSize="16px" lineHeight="16px" noWrap width="75px">
            Task List
          </Typography>
        </Button>
        <Grid container columnSpacing="16px">
          <Grid item sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <Stack direction="row">{history && <ToolbarHistory history={history} />}</Stack>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => discardTask()}
              sx={buttonStyle}
            >
              <Typography fontWeight={700}>Discard</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="inherit" sx={buttonStyle} onClick={() => mutate()}>
              <Typography fontWeight={700}>Save as Draft</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setFieldValue('openDialog', true)}
              disabled={!allowSubmit}
              sx={activeButtonStyle(allowSubmit)}
            >
              <Typography fontWeight={700}>Submit to Checker</Typography>
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </Box>
  );
}
