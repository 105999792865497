import { Navigate, createBrowserRouter } from 'react-router-dom';
import { AdminLayout, DefaultLayout, TaskRouterLayout } from '../layouts';
import Home from '../pages/home';
import Processing from '../pages/processing';
import Task from '../pages/task';
import AdminTask from './AdminTask';
import Admin from '../pages/admin';
import Session from './session';

export const routes = [
  {
    path: '',
    element: <DefaultLayout />,
    children: [
      {
        path: '',
        element: <Home />,
      },
    ],
  },
  {
    path: '/task',
    element: <TaskRouterLayout />,
    children: [
      {
        path: 'create',
        element: <Task type="new" draft />,
      },
      {
        path: 'processing',
        children: [
          {
            path: 'new',
            element: <Processing />,
          },
          {
            path: 'renewal',
            element: <Processing renewal />,
          },
        ],
      },
      {
        path: 'view',
        children: [
          {
            path: 'new',
            element: <Task type="new" />,
          },
          {
            path: 'renewal',
            element: <Task type="renewal" />,
          },
        ],
      },
      {
        path: 'completed',
        children: [
          {
            path: 'new',
            element: <Task type="new" />,
          },
          {
            path: 'renewal',
            element: <Task type="renewal" />,
          },
        ],
      },
    ],
  },
  {
    path: '/admin',
    element: <AdminLayout />,
    children: [
      {
        path: '',
        element: <Admin />,
      },
      {
        path: 'maker',
        element: <AdminTask />,
      },
      {
        path: 'review',
        element: <AdminTask checker />,
      },
      {
        path: 'completed',
        element: <AdminTask checker />,
      },
    ],
  },
];

export const browserRoute = [
  {
    path: '/',
    element: <Session />,
    children: routes,
  },
  {
    path: '/index.html',
    element: <Navigate to="/" replace />,
  },
];

const router = createBrowserRouter(browserRoute);

export default router;
